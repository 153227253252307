import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { messages } from './errorMessagesData';

@Injectable()
export class ErrorMessages {
  public MSG = (<any>messages)

  constructor() { }
  getError(field, error) {
    let message = '';
    if (error) {
      if (error.required) {
        const required = this.MSG.ERROR.REQUIRED;
        switch (field) {
          case "username": { message = required.Username; } break;
          case "phoneNumber": { message = required.phoneNumber; } break;
          case "mobile": { message = required.mobile; } break;
          case "password": { message = required.Password; } break;
          case "centreName": { message = required.centreName; } break;
          case "location": { message = required.location; } break;
          case "city": { message = required.city; } break;
          case "region": { message = required.region; } break;
          case "zipcode": { message = required.zipcode; } break;
          case "zipCode": { message = required.zipCode; } break;
          case "personName": { message = required.personName; } break;
          case "authoriseCentre": { message = required.authoriseCentre; } break;
          case "companyName": { message = required.companyName } break;
          case "state": { message = required.state } break;
          case "country": { message = required.country } break;
          case "address": { message = required.address } break;
          case "oldPassword": { message = required.oldPassword; } break;
          case "amount": { message = required.Amount; } break;
          case "newPassword": { message = required.newPassword; } break;
          case "confirmPassword": { message = required.confirmPassword; } break;
          case "activity": { message = required.activity; } break;
          case "remark": { message = required.remark; } break;
          case "firstname": { message = required.firstname; } break;
          case "lastname": { message = required.lastname; } break;
          case "phoneNo": { message = required.PhoneNo } break;
          case "technology": { message = required.technology; } break;
          case "emailId": { message = required.emailId; } break;
          case "roleName": { message = required.roleName; } break;
          case "filterName": { message = required.filterName; } break;
          case "emailKey": { message = required.emailKey; } break;
          case "subject": { message = required.subject; } break;
          case "fromEmail": { message = required.fromEmail; } break;
          case "emailTemplateId": { message = required.emailTemplateId; } break;
          case "host": { message = required.host; } break;
          case "port": { message = required.port; } break;
          case "defaultFromEmail": { message = required.defaultFromEmail; } break;
          case "metaTitle": { message = required.metaTitle; } break;
          case "metaDescription": { message = required.metaDescription; } break;
          case "description": { message = required.description; } break;
          case "metaKeyword": { message = required.metaKeyword; } break;
          case "pageTitle": { message = required.pageTitle; } break;
          case "currency": { message = required.currency; } break;
          case "dateFormat": { message = required.dateFormat; } break;
          case "defaultAdminEmail": { message = required.defaultAdminEmail; } break;
          case "entityType": { message = required.entityType } break;
          case "faxNumber": { message = required.faxNumber } break;
          case "pcentreName": { message = required.pcentreName } break;
          case "centreNumber": { message = required.centreNumber } break;
          case "content": { message = required.content } break;
          case "emailType": { message = required.emailType } break;
          case "templateName": { message = required.templateName } break;
          case "qualification": { message = required.qualification } break;
          case "templateType": { message = required.templateType } break;
          case "qualificationName": { message = required.qualificationName } break;
          case "categoryName": { message = required.categoryName } break;
          case "prepCentre": { message = required.prepCentre } break;
          case "examDate": { message = required.examDate } break;
          case "grade": { message = required.grade } break;
          case "stream": { message = required.stream } break;
          case "ammount": { message = required.ammount } break;
          case "examType": { message = required.examType } break;
          case "emailPassword": { message = required.emailPassword } break;
          case "emailSenderName": { message = required.emailSenderName } break;
          case "emailUserName": { message = required.emailUserName } break;
          case "emailHost": { message = required.emailHost } break;
          case "smsPassword": { message = required.smsPassword } break;
          case "smsSenderName": { message = required.smsSenderName } break;
          case "smsUserName": { message = required.smsUserName } break;
          case "smsHost": { message = required.smsHost } break;
          case "paymentPassword": { message = required.paymentPassword } break;
          case "paymentSenderName": { message = required.paymentSenderName } break;
          case "paymentUserName": { message = required.paymentUserName } break;
          case "paymentHost": { message = required.paymentHost } break;
          case "smsPort": { message = required.smsPort } break;
          case "emailPort": { message = required.emailPort } break;
          case "paymentPort": { message = required.paymentPort } break;
          case "smsAuth": { message = required.smsAuth } break;
          case "smsId": { message = required.smsId } break;
          case "paymentId": { message = required.paymentId } break;
          case "smsPhone": { message = required.smsPhone } break;
          case "cardNumber": { message = required.cardNumber } break;
          case "emailKeySend": { message = required.emailKeySend } break;
          case "emailUser": { message = required.emailUser } break;
          case "cmsType": { message = required.cmsType } break;
          case "categoryType": { message = required.categoryType } break;
          case "cutoffdate": { message = required.cutoffdate } break;
          case "date": { message = required.date } break;
          case "flexibleDate": { message = required.flexibleDate } break;




        }
      } else if (error.pattern) {
        const pattern = this.MSG.ERROR.PATTERN;
        switch (field) {
          case 'emailId': { message = pattern.Email } break;
          case 'newPassword': { message = pattern.newPassword } break;
          case 'password': { message = pattern.password } break;
          case 'confirmPassword': { message = pattern.confirmPassword } break;
          case 'adminEmail': { message = pattern.adminEmail } break;
          case 'fromEmail': { message = pattern.fromEmail } break;
          case 'defaultFromEmail': { message = pattern.Email } break;
          case 'defaultAdminEmail': { message = pattern.Email } break;
          case "oldPassword": { message = pattern.oldPassword; } break;
          case "pcentreName": { message = pattern.pcentreName } break;
          case "phoneNumber": { message = pattern.phoneNumber } break;
          case "faxNumber": { message = pattern.faxNumber } break;
          case "centreNumber": { message = pattern.centreNumber } break;
          case "emailUser": { message = pattern.emailUser } break;
          case "recipientType": { message = pattern.recipientType } break;


        }
      } else if (error.minlength) {
        const minlength = this.MSG.ERROR.MINLENGTH;
        switch (field) {
          case 'mobile': { message = minlength.PhoneNo } break;
          case 'password': { message = minlength.password } break;
          case 'confirmPassword': { message = minlength.confirmPassword } break;
          case 'newPassword': { message = minlength.newPassword } break;
          case 'port': { message = minlength.port } break;
          case 'phoneNumber': { message = minlength.phoneNumber } break;
          case 'zipcode': { message = minlength.zipcode } break;
          case 'zipCode': { message = minlength.zipCode } break;
          case 'faxNumber': { message = minlength.faxNumber } break;




        }
      }
      return message;
    }
  }
}
