export const messages =
{
    "ERROR": {
        "REQUIRED": {
            "Email": "Enter Email Address",
            "Password": "Enter Password",
            "Username": "Enter Username",
            "currentPassword": "Enter Current Password",
            "oldPassword": "Enter Old Password",
            "newPassword": "Enter new password",
            "confirmPassword": "Enter confirm password",
            "personName": 'Enter Contact Person Name',
            "phoneNumber": 'Enter phone Number',
            "location": 'Choose Location',
            "city": 'Select City',
            "region": 'Choose Region',
            "zipcode": 'Enter Zipcode',
            "zipCode": 'Enter Zipcode',
            "activity": "Enter activity detail",
            "remark": "Enter teachers remark",
            "firstname": "Enter First Name",
            "centreName": "Enter Authorised Centre Name",
            "authoriseCentre": "Choose Authorise Centre",
            "lastname": "Enter Last Name",
            "mobile": "Enter mobile number",
            "emailId": "Enter Email",
            "roleName": "Enter Role",
            "technology": "Select Technology",
            "PhoneNo": " Enter Mobile Number",
            "Amount": " Enter Amount",
            "filterName": "Enter Filter Name",
            "emailKey": "Enter Title",
            "subject": "Enter Subject",
            "fromEmail": "Enter From E-mail",
            "emailTemplateId": "Select Event",
            "host": "Enter Host",
            "port": "Enter Port",
            "defaultFromEmail": "Enter default From e-mail",
            "defaultAdminEmail": "Enter default Admin e-mail",
            "metaTitle": "Enter Meta Title",
            "metaDescription": "Enter Meta Description",
            "description": "Enter Description",
            "metaKeyword": "Enter Meta Keyword",
            "pageTitle": "Enter Page Title",
            "currency": "Select Currency",
            "dateFormat": "Select Date Format",
            "companyName": "Enter Company Name",
            "address": "Enter Address",
            "country": "Select Country",
            "state": "Select State",
            "entityType": "Select Entity Type",
            "recipientType": "Select Recipient Type",
            "faxNumber": "Enter Fax Number",
            "pcentreName": "Enter Preparation Centre Name",
            "centreNumber": "Enter Centre Number",
            "content": "Enter Content",
            "emailType": "Select EmailType",
            "templateName": "Enter Template Name",
            "qualification": "Select Exam",
            "templateType": "Select Template Type",
            "qualificationName": "Enter Exam Name",
            "categoryName": "Choose Entity Type",
            "prepCentre": "Select Preparation Centre",
            "contactNumber": "Enter Contact No",
            "candidateName": "Enter Candidate Name",
            "examType": "Choose Exam Type",
            "stream": "Choose Stream",
            "ammount": "Enter Ammount",
            "grade": "Choose Grade",
            "examDate": "Choose Date",
            "emailPassword": "Enter Password",
            "emailSenderName": "Enter Sender Name",
            "emailUserName": "Enter User Name",
            "emailHost": "Enter Host",
            "smsPassword": "Enter Password",
            "smsSenderName": "Enter Sender Name",
            "smsUserName": "Enter User Name",
            "smsHost": "Enter Host",
            "paymentPassword": "Enter Password",
            "paymentSenderName": "Enter Sende rName",
            "paymentUserName": "Enter User Name",
            "paymentHost": "Enter Host",
            "paymentPort": "Enter Port",
            "smsPort": "Enter Port",
            "emailPort": "Enter Port",
            "smsAuth": "Enter Authorisation",
            "smsId": "Enter SmsId",
            "paymentId": "Enter PaymentId",
            "smsPhone": "Enter PhoneNumber",
            "cardNumber": "Enter CardNumber",
            "emailKeySend": "Enter Key",
            "emailUser": "Enter User Email",
            "categoryType": "Choose Category Type",
            "cutoffdate": "Enter No.of cut-off days",
            "date":"Choose Date",
            "flexibleDate":"Choose Flexible Date"
        },
        "PATTERN": {
            "Age": "Please enter proper Age",
            "Mobile": "Phone number can not be less than 4 characters",
            "Email": "Please enter a valid email address",
            "password": "Password must contain atleast one Capital and one small letter and one number and one special character",
            "Username": "Invalid Username",
            "Contactno": "Invalid Contact Number",
            "emailId": "Enter valid E-mail",
            "fromEmail": "Enter valid E-mail",
            "adminEmail": "Enter valid E-mail",
            "newPassword": 'Password must contain atleast one Capital and one small letter and one number and one special character',
            "confirmPassword": 'Password must contain atleast one Capital and one small letter and one number and one special character',
            "oldPassword": 'Password must contain atleast one Capital and one small letter and one number and one special character',
            "pcentreName": "centreName must contain alphabets only",
            "centreNumber": "centreNumber must contain numbers only",
            "faxNumber": "faxNumber must contain numbers only",
            "phoneNumber": "phoneNumber must contain numbers only",
            "ammount": "Ammount must contain numbers only",
            "emailUser": "Enter Valid Email",
            "cmsType": "Select Cms Type"
        },
        "MINLENGTH": {
            "mobile": "Mobile number should be 10 digits",
            "PhoneNo": "Mobile number should be 10 digits",
            "phoneNumber": "Phone number should be 10 digits",
            "password": "Password must be atleast 6 characters",
            "zipcode": "Zipcode should be 6 characters",
            "zipCode": "Zipcode should be 6 characters",
            "Distance": "Distance should be minimum 1 characters",
            "PinNo": "Please enter minimum 6 pin number",
            // "FirstName" : "Distance should not be more than 5 chartacters",
            "LastName": "Distance should not be more than 5 chartacters",
            "Username": "Username must be atleast 5 characters",
            "confirmPassword": "Required atleast 6 characters",
            "newPassword": "Required atleast 6 characters",
            "faxNumber": "Required atleast 8 characters",
            "port": "Minimum 3 didgit required",
        },
        "CUSTOM": {
            "ConfirmPassword": "Confirm password does not match!",
            "Location": "Please enter valid location",
            "Subject": "Please select subject from available list"
        }
    }
}

