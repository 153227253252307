import { Component, OnInit, Injector } from '@angular/core';
import { BaseComponent } from 'src/app/common/commonComponent';
import { Location } from "@angular/common";

@Component({
  selector: 'app-add-editcsv',
  templateUrl: './add-editcsv.component.html',
  styles: []
})
export class AddEditcsvComponent extends BaseComponent implements OnInit {
  public adminUserId: any;
  public roleList: Array<any> = [];
  public title: any;
  public passwordShow: boolean = true;
  public confirmPasswordShow: boolean = true;
  public entityArr: Array<any> = [];
  public subType: any = {};
  public centre = { photo: '', firstname: '', lastname: '', role: '', emailId: '', mobile: '', status: '' };
  public submitted = false;
  public qualification: any = {};
  public statusList: Array<any> = [{ name: 'Active', value: true }, { name: 'inactive', value: false }];
  student: any = {};
  public data: any;
  constructor(public inj: Injector, private location: Location) {
    super(inj);
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.adminUserId = params.id;
      }
    })
    // this.getRoleList();
    this.title = 'Add Qualification';
    if (this.adminUserId) {
      this.title = 'Update Qualification';
    }
    this.getStudentDetails();

  }

  ngOnInit() {

  }
  /****************************************************************************
     @PURPOSE      : To get StudentDetails.
   /****************************************************************************/
  getStudentDetails() {
    setTimeout(() => {
      this.commonService.callApi('csvfile/getStudentDetails', { _id: this.data }, 'post', false, false).then(success => {
        if (success.status == 1) {
          this.student = success.data.listing[0];
          if (this.student.studentJourney) {
            this.student.year = this.student.studentJourney[0].qualificationStartYear + '-' + this.student.studentJourney[0].qualificationEndYear
          }
        }
        else {
          this.popToast('error', success.msg)
        }
      })
    }, 10);
  }


  /***************************************************************
  @Purpose :Add or Update Csv User.
  /***************************************************************/
  updateCSvUser(qualificationForm, student) {
    this.submitted = true;
    if (qualificationForm.valid) {
      if (this.adminUserId) {
        // qualification._id = qualification._id;
        this.commonService.callApi(this.callAPIConstants.UPDATEQUALIFICATION, student, 'post', false, false).then(success => {
          if (success.status == 1) {
            this.router.navigate([this.URLConstants.QUALIFICATIONLIST]);
            this.popToast("success", success.msg);
          }
        })
      }
      else {
        this.commonService.callApi('csvfile/updateCsvUsers', student, 'post', false, false).then(success => {
          if (success.status == 1) {
            this.popToast("success", success.message);
            // this.router.navigate([this.URLConstants.CSVSTUDENTLIST]);
            this.broadcaster.broadcast('studentUpdated', true)
            this.submitted = false;
            this.BsModalRef.hide();
          } else {
            this.popToast("error", success.message);
          }
        })
      }
    }
  }
  /***************************************************************/
  /************************************************************** 
    @Purpose :To go back to previous page
    //************************************************************** */
  goBack() {
    // this.location.back();
    this.BsModalRef.hide();
  }
}
