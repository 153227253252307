export const callAPIConstants = {
    AdminLogin: 'admin/login',
    AdminForgotPassword: 'admin/ForgotPassword',
    AdminResetPassword: 'admin/resetPassword',
    AdminSetPassword: 'admin/setPassword',
    AdminGetProfile: 'admin/profile',
    AdminFileUpload: 'admin/fileUpload',
    AdminEditProfile: 'admin/editProfile',
    AdminChangePassword: 'admin/changePassword',
    AdminLogout: 'admin/logout',
    UserList: 'user/userListing',
    CSVLeadUsers: 'csvleadfile/getLeadCsvBasedOnCatAndQualification',
    UserDeleteUsers: 'user/deleteUsers',
    UserChangeStatus: 'user/changeStatus',
    GetUserDetails: 'user/userProfile/',
    UserCSVforDataTable: 'user/downloadCsv',
    UserEXCELforDataTable: 'user/downloadExcel',
    UserColumnVisibleSettings: 'user/columnSettings',
    UserGetColumnData: 'user/getColumnValues',
    UserSaveFilter: 'user/saveFilter',
    UserGetFilters: 'user/getFilters',
    UserDeleteFilter: 'user/deleteFilter/',
    ACLIST: 'admin/authCentreListing',
    PCLIST: 'admin/prepCentreListing',
    AdminUserlist: 'admin/userListing',
    AdminGetColumnData: 'admin/getColumnValues',
    AdminColumnVisibleSettings: 'admin/columnSettings',
    AUTHCENTRECOLUMNSETTINGS: 'authcentre/columnSettings',
    AdminDeleteUsers: 'admin/deleteUsers',
    AdminChangeStatus: 'admin/changeStatus',
    AdminCSVforDataTable: 'admin/downloadCsv',
    AdminEXCELforDataTable: 'admin/downloadExcel',
    AdminGetUserDetails: 'admin/userProfile/',
    AdminAddAdminUser: 'admin/addAdminUser',
    AdminSaveFilter: 'admin/SaveFilter',
    AdminGetFilters: 'admin/getFilters',
    AdminDeleteFilter: 'admin/deleteFilter/',
    AdminUpdateUser: 'admin/updateAdminUser',
    EmailTemplateList: 'listEmail',
    EmailTemplateDetail: 'detailEmail/',
    EmialColumnData: 'getEmailColumnValues',
    EmialColumnVisibleSettings: 'emailColumnSettings',
    DeleteEmailTemplate: 'deleteTemplate',
    EmailAddNewEmail: 'addUpdateEmail',
    EmailChangeStatus: 'changeTemplateStatus',
    AdminEmailGetEmailTitle: 'getEmailTitle',
    AdminEmailGetEmailSettings: 'getEmailSettings',
    AdminEmailAddEmailSettings: 'addEmailSettings',
    AdminEmailaddDefaultEmailSettings: 'addDefaultEmailSettings',
    AdminEmailDeleteEmailSettings: "deleteEmailSetting/",
    GetSMTPsettings: 'getSMTPSettings',
    AddSettings: 'addSettings',
    cmsList: "cmsList",
    cmsDelete: "cmsDelete",
    cmsDetail: "cmsDetail/",
    cmsUpdate: "addUpdateCMS",
    GlobalSettingsUpdate: 'addGlobalSettings',
    GlobalSettingsGetCurrency: "getCurrency",
    GlobalSettignsGetDateFormat: "getDateFormat",
    GlobalSettignsGlobalSettings: "getGlobalSettings",
    RoleList: 'listRole',
    AddUpdateRole: "addRole",
    RoleChangeStatus: "changeRoleStatus",
    RoleDelete: "deleteRoles",
    RoleGetColumnData: "getRoleColumnValues",
    GetRoleDetail: 'getRolePermission/',
    getRoles:'getRole',
    ADDAC: "authcentre/register",
    ADDPC: "prepcentre/register",
    ACPCDETAILS: 'admin/userProfile',
    AUTHCENTRELIST: 'admin/getAuthCentreList',
    QUALIFICATIONLIST: 'admin/qualificationListing',
    QUALIFICATIONDETAIL: 'admin/getQualification',
    UPDATEAC: 'admin/updateAuthCentre',
    UPDATEPC: 'admin/updatePrepCentre',
    AUTHCENTRESEARCH: 'admin/authCentreSearch',
    PREPCENTRESEARCH: 'admin/prepCentreSearch',
    EMAILSEARCH: 'notification/searchTemplate',
    ADDUPDATETEMPLATE: 'notification/addUpdateTemplate',
    EMAILLIST: 'notification/listTemplate',
    DELETETEMPLATE: 'notification/deleteNotificationTemplate',
    ADDQUALIFICATION: 'admin/addQualification',
    UPDATEQUALIFICATION: 'admin/editQualification',
    CSVLIST: 'csvfile/getCsvLists',
    LEADSLIST: 'csvleadfile/getCsvLeadFileLists',
    LEADUSERSLIST: 'csvleadfile/getCsvLeadUserLists',
    LeadsFilterList: 'csvleadfile/getColumnValues',
    STUDENTSCSVLIST: 'csvfile/getCsvUsers',
    AUTHSAVEFILTER: 'authcentre/saveFilter',
    ADDEXAM: 'exam/addExams',
    LISTEXAMS: 'exam/adminExamLists',
    GETEXAMDETAILS: 'exam/',
    UpdateExam: '/exam/editExams',
    massEmailSMSNotificationSend: 'admin/sendNotifications',
    ADMIN_CHARTDATA: 'admin/adminAnalyticsData',
    ScheduledExamDetailById: '/exam/oneExamDetails',
    getACbasedOnQualification: 'admin/getACbasedOnQualification',
    updatePrepCentre: 'admin/updatePrepCentre',
    LeadUpload: '/csvleadfile/uploadCsvLeadFile ',
    DownloadLeads: '/csvleadfile/downloadLeadFile',
    LeadSendMail: '/csvleadfile/sendMail',
    paidRegisteredUserListing: 'admin/paidRegisteredUserListing',
    NotpaidRegisteredUserListing: 'admin/notPaidRegisteredUserListing',
    csvRegisteredUserListing: 'admin/registeredCsvUserListing',
    TobeRegisteredUserListing: 'admin/toBeRegisteredUserListing',
    seletedLeadData: 'notification/templatesBeasedOnQualification',
    AddUpdateAdminUser: 'addAdmin'
}
