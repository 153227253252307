import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// routing
import { AppRoutingModule, AppRoutingComponents } from './app-routing.module';

// reusable
import { SharedModule } from './reusable/shared/shared.module'

// Common
import { CommonService } from './common/common.service';
import { CanLoginActivate, CanAuthActivate } from './common/auth.gaurd';
import { ErrorMessages } from './common/errorMessages';
import { Broadcaster } from './common/broadCaster';

// plugins
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2'
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { BsDropdownDirective, TabsModule, BsDropdownModule } from 'ngx-bootstrap';

// component
import { AppComponent } from './app.component';
import { GooglePlacesDirective } from './common/directives/google-places.directive';
// import { SelectCsvLeadsComponent } from './modals/select-csv-leads/select-csv-leads.component';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    CanLoginActivate,
    CanAuthActivate,
    AppRoutingComponents,
    // SelectCsvLeadsComponent,
    // GooglePlacesDirective
  ],
  imports: [
    SharedModule,
    ModalModule.forRoot(),
    BrowserModule,
    HttpClientModule,
    SweetAlert2Module.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    TabsModule.forRoot(),
    BsDropdownModule.forRoot(),
    // GooglePlacesDirective,

  ],
  exports: [
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    CanLoginActivate,
    CanAuthActivate,
    CommonService,
    BsDropdownDirective,
    ErrorMessages,
    Broadcaster,
    BsModalRef,

    // GooglePlacesDirective,
  ]
})
export class AppModule { }
