import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationModule, ModalModule, SortableModule, BsDropdownModule, CollapseModule } from 'ngx-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { SortService } from './sortService';
import { SortPipe, SearchFilter, SortableTableDirective, SortableColumnComponent, SafePipe } from './bn-datatable.component'

@NgModule({
  imports: [
    CommonModule,
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    SortableModule.forRoot(),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    NgSelectModule
  ],
  declarations: [
    SortableColumnComponent,
    SortableTableDirective,
    SortPipe,
    SearchFilter,
    SafePipe,
  ],
  providers: [
    SortService
  ],
  exports: [
    SortableColumnComponent,
    SortableTableDirective,
    SortPipe,
    SearchFilter,
    CommonModule,
    PaginationModule,
    BsDropdownModule,
    CollapseModule,
    ModalModule,
    SortableModule,
    NgSelectModule,
    SafePipe,
  ]
})
export class BnDatatableModule { }
