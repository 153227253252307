import { Component, OnInit, Injector, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from 'src/app/common/commonComponent';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { Location } from "@angular/common";
import { NavigationExtras } from '@angular/router'

@Component({
  selector: 'app-add-edit-ac-pc',
  templateUrl: './add-edit-ac-pc.component.html',
  styles: []
})
export class AddEditAcPcComponent extends BaseComponent implements OnInit {
  public passwordShow: boolean = true;
  public confirmPasswordShow: boolean = true;
  public adminUserId: any;
  public roleList: Array<any> = [];
  public examsArr: Array<any> = [];
  public file: any;
  public apiUrl: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  public sliceSize: any;
  public imageURL = "";
  public exams: any;
  public paymentTypes: any;
  @Input() subType: any;
  public statusList: Array<any> = [{ name: 'Active', value: true }, { name: 'inactive', value: false }];
  public entityArr: Array<any> = [];
  public authCentreArr: Array<any> = [];
  public paymentArr: Array<any> = [{ name: 'Offline' }, { name: 'Online' }];
  public qualificationArr: Array<any> = [];
  public prepCentreArr: Array<any> = [];
  public centre: any = {};
  public locations: any = {};
  public submitted = false;
  public files: NgxFileDropEntry[] = [];
  public imageUrl;
  public fileUpload = new FormData();
  public countryArr: Array<any> = [];
  public stateArr: Array<any> = [];
  public cityArr: Array<any> = [];
  public locationEvent: any = {};
  selectedexamsArr: Array<any> = [];
  constructor(inj: Injector, private location: Location) {
    super(inj)
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.adminUserId = params.id;
        this.getFormDetails();
        // this.getEntityTypes();
      }
      else {
        // this.getEntityTypes();

      }
    })
  }

  ngOnInit() {
    this.centre.qualificationOrExamLists = [];
    this.centre.qualificationOrExamLists1 = [];
    this.centre.acceptPaymentType = [];
    this.locations = { isCountry: true };
    // this.centre.country = 'India';
    // this.centre.countryId = "5df09de5cb5c1c74bbd4452a";
    if (!this.adminUserId) {
      this.getState();
    }
    this.getLocations();
    if (this.subType.subType == 'STUDENT') {
      this.getPrepCentres();
    }
    if (this.subType.subType == 'AC') {
      this.getEntityTypes();
    }
  }
  /*************************************************************
@purpose :To Get FormDetails.
/*************************************************************/
  getFormDetails() {
    setTimeout(() => {
      this.commonService.callApi(`admin/userProfile/${this.adminUserId}/${this.subType.subType == 'AC' ? 'authCentre' : this.subType.subType == 'PC' ? 'prepCentre' : ''}`, '', 'get', false, false).then(success => {
        if (success.status === 1) {
          this.centre = success.data;
          if (success.data.acceptPaymentType && success.data.acceptPaymentType.length) {
            success.data.acceptPaymentType.forEach(obj => {
              this.paymentArr.forEach(data => {
                if (data.name === obj) {
                  data.checked = true;
                }
              })
            })
          }
          if (success.data.qualificationOrExamLists && success.data.qualificationOrExamLists.length) {
            success.data.qualificationOrExamLists.forEach(obj => {
              this.examsArr.forEach(data => {
                if (data._id === obj) {
                  data.checked = true;
                }
              })
            })
          }
          if (this.centre.countryId) {
            this.getState({ _id: this.centre.countryId });
            this.getCity(this.centre.state);
          }
          // this.getQualifications(this.centre.categoryType, '', this.centre.qualificationOrExamLists);
        }
      })
    }, 10);
  }

  /***************************************************************
 @purpose : Add or Update AC,PC,STUDENT //
  /***************************************************************/
  addAcPC(centreForm, centre) {
    this.submitted = true;
    if (centreForm.valid && this.centre.acceptPaymentType.length) {
      let isQualificationSelected = true;
      // this.examsArr.forEach(examData => {
      //   examData.qualifications.forEach(qualificationData => {
      //     if (!this.centre.qualificationOrExamLists[examData.categoryType] || !this.centre.qualificationOrExamLists[examData.categoryType].length) {
      //       isQualificationSelected = false;
      //     }
      //   })
      // })
      if (isQualificationSelected && this.examsArr && this.examsArr.length && this.centre.qualificationOrExamLists && this.centre.qualificationOrExamLists.length) {
        if (this.adminUserId) {
          ((this.subType.subType === 'AC') ? this.centre.authCentreId = centre._id : (this.subType.subType == 'PC') ? this.centre.prepCentreId = centre._id : this.centre._id = centre._id)
          // centre.authCentreId = centre._id;
          if (this.subType) {
            (this.subType.subType === 'AC') ? this.apiUrl = this.callAPIConstants.UPDATEAC : (this.subType.subType = 'PC') ? this.apiUrl = this.callAPIConstants.UPDATEPC : this.apiUrl = this.callAPIConstants.AdminUpdateUser
          }
          let data = JSON.parse(JSON.stringify(this.centre));
          // data.qualificationOrExamLists = [];
          // this.examsArr.forEach(examData => {
          //   this.centre.qualificationOrExamLists[examData.categoryType].forEach(qualificationId => {
          //     data.qualificationOrExamLists.push(qualificationId)
          //   })
          // })

          this.commonService.callApi(this.apiUrl, data, 'post', false, false).then(success => {
            if (success.status == 1) {
              this.popToast("success", "Authorised Centre Updated successfully.");
              let routerUrl: any;
              if (this.subType) {
                (this.subType.subType == 'AC') ? routerUrl =
                  this.URLConstants.AUTH : (this.subType.subType = 'PC') ?
                  routerUrl = this.URLConstants.PREPARATION : routerUrl =
                  this.URLConstants.STUDENT
                this.router.navigate([routerUrl])
              }
            }
            else {
              this.popToast('error', success.message);
            }
          })
        } else {
          if (this.subType.subType == 'AC') {
            // this.examsArr.forEach(exam => {
            //   exam.qualifications.forEach(obj => {
            //     this.centre.qualificationOrExamLists.forEach(obj1 => {
            //       if (obj.qualificationId == obj1) {
            //         this.centre.qualificationOrExamLists.push(obj1);
            //       }
            //     })
            //   })

            // })
            let data = JSON.parse(JSON.stringify(this.centre));
            // data.qualificationOrExamLists = [];
            // this.examsArr.forEach(examData => {
            //   this.centre.qualificationOrExamLists[examData.categoryType].forEach(qualificationId => {
            //     data.qualificationOrExamLists.push(qualificationId)
            //   })
            // })

            this.commonService.callApi(this.callAPIConstants.ADDAC, data, 'post', false, false).then(success => {
              if (success.status == 1) {
                this.popToast("success", "Authorised Centre created successfully.");
                if (this.subType) {
                  let routerUrl: any;
                  (this.subType.subType == 'AC') ? routerUrl =
                    this.URLConstants.AUTH : (this.subType.subType = 'PC') ?
                    routerUrl = this.URLConstants.PREPARATION : routerUrl =
                    this.URLConstants.STUDENT
                  this.router.navigate([routerUrl])
                }
                this.submitted = false;
              } else {
                this.popToast("error", success.message);
              }
            })
          }
        }
      }
    }
  }
  /***************************************************************/

  /***************************************************************
 @Purpose : Image Uploading.
  /***************************************************************/

  uploadImage() {
    this.commonService.callApi(this.callAPIConstants.AdminFileUpload, this.fileUpload, 'post', false, true).then(success => {
      if (success.status == 1) {
        this.centre.photo = success.data.filePath;
        this.imageUrl = '';
        this.fileUpload.delete("file");
      }
    })
  }

  cancelUploadImage() {
    this.imageUrl = '';
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          let reader = new FileReader();
          reader.onload = (e: any) => {
            this.imageUrl = this.sanitize.bypassSecurityTrustUrl(e.target.result);
            this.fileUpload.append("file", file);
          }
          reader.readAsDataURL(file);
        });
      }
    }
  }
  /***************************************************************/
  showHidePassword(key, type) {
    if (type == 'password') {
      this.passwordShow = !this.passwordShow;
    }
    else {
      this.confirmPasswordShow = !this.confirmPasswordShow
    }
  }
  /************************************************************** 
  @Purpose :To go back to previous page
  //************************************************************** */
  goBack() {
    this.location.back();
  }
  /****************************************************************************
    @PURPOSE      : image uploading
  /****************************************************************************/
  imageCropped(event) {
    // this.croppedImage = event.base64;
    var ImageURL = event.base64;
    // Split the base64 string in data and contentType
    var block = ImageURL.split(";");
    // Get the content type of the image
    var contentType = block[0].split(":")[1];// In this case "image/gif"
    // get the real base64 content of the file
    var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."
    // Convert it to a blob to upload
    this.b64toBlob(realData, contentType, this.sliceSize);

  }

  imageLoaded() {
    // show cropper
  }

  loadImageFailed() {
    // show message
  }

  fileChangeEvent(event: any, template): void {
    this.imageChangedEvent = event;
    this.BsModalRef = this.modalService.show(template);
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    this.file = this.blobToFile(blob, contentType);
  }
  private blobToFile(theBlob: Blob, fileName: string): File {
    const b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = fileName;
    return b as File;
  }

  submitcropped() {
    this.BsModalRef.hide();
    var fd = new FormData();
    fd.append('file', this.file)
    this.commonService.callApi(this.callAPIConstants.AdminFileUpload, fd, 'post', true, true).then(success => {
      if (success.status === 1) {
        this.centre.photo = success.data.filePath;

      } else {
        this.popToast('error', success.message)
      }
    })
  }
  openfile(event: any) {
    event.preventDefault();
    event.preventDefault();
    let element = document.getElementById('profile');
    element.click();
  }
  /****************************************************************************/

  /****************************************************************************
   @PURPOSE      : To select checkBoxes.
 /****************************************************************************/
  selectedCheckbox(categoryType, data, key) {

    if (key == 'qualification') {
      let count = 0;
      let index;

      for (let k = 0; k < this.centre.qualificationOrExamLists.length; k++) {
        if (data._id == this.centre.qualificationOrExamLists[k]) {
          count++;
          index = k;
        }
      }
      if (count) {
        this.centre.qualificationOrExamLists.splice(index, 1);
      } else {
        this.centre.qualificationOrExamLists.push(data._id);

      }
      // this.setToken('selectedExamsArr', JSON.stringify(this.examsArr));
    }
    else if (key == 'exam') {
      let count = 0;
      let index;
      for (let k = 0; k < this.centre.acceptPaymentType.length; k++) {
        if (data.name == this.centre.acceptPaymentType[k]) {
          count++;
          index = k;
        }
      }
      if (count) {
        this.centre.acceptPaymentType.splice(index, 1);
      } else {
        this.centre.acceptPaymentType.push(data.name);
      }
    }
  }
  /*************************************************************/
  /****************************************************************************
     @PURPOSE      : To get EntityTypes.
   /****************************************************************************/
  getEntityTypes() {
    let data = ["5da8077dbcd71b1948751375", "5da807903390fe195181d5c5", "5da807d88db8f3197a89a39b", "5df0769d1de69b8976ae7a1f"]
    this.commonService.callApi('admin/getQualification', { isFixedQualification: true }, 'post', false, false).then(success => {
      if (success.status == 1) {
        this.entityArr = success.data.categoryData;
        this.examsArr = success.data;
      }
      else {
        this.popToast('error', success.msg)
      }
    })
  }
  /****************************************************************************
     @PURPOSE      : To Get Preparation Centres.
   /****************************************************************************/
  getPrepCentres() {
    this.commonService.callApi('/admin/getPrepCentreList', '', 'get', false, false).then(success => {
      if (success.status == 1) {
        this.prepCentreArr = success.data;
      }
    })
  }
  predefiendChecked = false;
  /****************************************************************************
    @PURPOSE      : TO get Qualificationas.
  /****************************************************************************/
  getQualifications(id?, change?, qualifications?) {
    if (id && id.length) {
      let categoryId;
      let categoryId1;
      let examData = JSON.parse(JSON.stringify((this.examsArr)));
      this.commonService.callApi('/admin/getQualification', {}, 'post').then(success => {
        if (success.status == 1) {
        }
        else {

        }
      })

    }
    else {
      delete this.centre.categoryType;
      this.examsArr = [];
    }
  }
  /****************************************************************************
   @PURPOSE      : To prepopulate the address,city,state,country.
 /****************************************************************************/
  placeChanged(addressObj) {
    this.zone.run(() => {
      this.centre.address = addressObj.address;
      this.centre.city = addressObj.city;
      this.centre.state = addressObj.state;
      this.centre.country = addressObj.country;
      this.centre.countryAlphaCode = addressObj.countryCode;
    })
  }
  /****************************************************************************
   @PURPOSE      : When address changed.
 /****************************************************************************/
  addressChanged(value) {
    if (value.trim().length == 0) {
      this.centre.city = '';
      this.centre.country = '';
      this.centre.state = '';
    }
  }
  getLocations() {
    let data = {
      isCountry: true
    }
    this.commonService.callApi('admin/getCountryStateCity', data, 'post').then(success => {
      if (success.status == 1) {
        this.countryArr = success.data;
      }
    })
  }
  getState(event?, change?) {
    // if (event._id) {
    //   this.centre.countryId = event._id;
    //   this.locationEvent = event;
    // }
    // else {
    //   this.centre.countryId = event;
    // }
    if (change) {
      delete this.centre.state;
      delete this.centre.city;
      if (this.cityArr.length) {
        this.cityArr = [];
      }
    }
    // if (this.centre.state && event._id) {
    //   this.centre.state = '';
    // }
    // if (this.centre.city && event._id) {
    //   this.centre.city = '';
    // }

    this.locations = {};
    // this.locations._id = this.centre.countryId;
    if (event) {
      this.locations._id = event._id;
      this.locations.isState = true;
    } else {
      this.locations._id = this.centre.countryId;
      this.locations.isState = true;
    }

    this.commonService.callApi('admin/getCountryStateCity', this.locations, 'post').then(success => {
      if (success.status == 1) {
        this.stateArr = success.data;
      }
    })
  }
  getCity(name, change?) {
    // if (this.centre.city && this.locationEvent._id) {
    //   this.centre.city = '';
    // }
    if (change) {
      delete this.centre.city;
    }
    this.locations = {};
    this.locations.state = name;
    this.locations.isCity = true;
    // this.locations._id = this.centre.countryId;
    this.locations._id = this.centre.country;
    this.commonService.callApi('admin/getCountryStateCity', this.locations, 'post').then(success => {
      if (success.status == 1) {
        this.cityArr = success.data;
      }
    })
  }
  ngOnDestroy() {
    if (this.getToken('selectedExamsArr')) {
      this.removeToken('selectedExamsArr');
    }
  }
}

