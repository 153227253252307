import { Component, OnInit, Injector, Input } from '@angular/core';
import { BaseComponent } from '../../common/commonComponent';
// import * as grapesjs from 'grapesjs';
// import 'grapesjs-preset-webpage';
// import 'grapesjs-preset-newsletter'
import grapesjs from 'grapesjs';
import 'grapesjs-preset-newsletter'
import 'grapesjs-preset-webpage';

@Component({
  selector: 'app-grapes-js',
  templateUrl: './grapes-js.component.html',
  styles: []
})
export class GrapesJSComponent extends BaseComponent implements OnInit {
  public _editor: any;
  public emailTemplateId: any;
  public blocks: any;
  public fieldList = ['fullname', 'lastname', 'role'];
  public showRichTextEditor: boolean;
  public blockManager: any;
  public panelManager: any;
  @Input('emailTemplate') emailTemplate: any;
  @Input('type') type: any;
  public plugin: any;

  constructor(public inj: Injector) {
    super(inj);

  }

  ngOnInit() {
    setTimeout(() => {
      if (this.emailTemplate && this.type) {
        if (this.type == 'emailDetail' || this.type == 'emailDetails' || this.type == 'cmsDetail') {
          this.showRichTextEditor = true;
          this.blocks = ['default', 'Extra', 'forms', 'link-block', 'quote', 'image', 'video', 'text', 'column1', 'column2', 'column3'];
          this.plugin = 'grapesjs-preset-newsletter'
        } else {
          this.showRichTextEditor = false;
          this.blocks = ['image', 'text'];
          this.plugin = 'grapesjs-preset-newsletter';
        }
        this._editor = this.initializeEditor();
        if (this._editor.RichTextEditor.get("insert")) { this._editor.RichTextEditor.remove('insert'); }
        if (this.showRichTextEditor) {
          /*************************************************************/
          // Add Custom Richtexteditor
          /*************************************************************/
          let getNgSelect = document.getElementById('dynamic');
          if (this._editor.RichTextEditor.get("insert")) { this._editor.RichTextEditor.remove('insert'); }
          this._editor.RichTextEditor.add('insert', {
            icon: getNgSelect,
            result: (rte, action) => {
              let data = rte.el.firstChild.ownerDocument;
              if (this.insertValue) {
                data.execCommand("insertText", false, '{{' + this.insertValue + '}}');
                this.insertValue = '';
              }
            },
          })
          /*************************************************************/
        }
        this.panelManager = this._editor.Panels;
        this.panelManager.addButton("options", {
          id: 'undo',
          className: 'fa fa-undo',
          command: e => e.runCommand('core:undo'),
          attributes: { title: 'Some title' },
          active: true,
        });
        this.panelManager.addButton("options", {
          id: 'delete',
          className: 'fa fa-trash',
          command: e => {
            let res = window.confirm('Are you sure want to clear the canvas?');
            if (res) {
              e.runCommand('core:canvas-clear')
            }
          },
          attributes: { title: 'Delete' },
          active: true,
        });
        this.blockManager = this._editor.BlockManager;
        this.blockManager.remove("sect37");
        this.blockManager.remove("button");
        this.blockManager.remove("divider");
        this.blockManager.remove("link");
        this.blockManager.remove("grid-items");
        this.blockManager.remove("list-items");

      }
    }, 1000)
  }
  ngDoCheck() {
    if (this._editor) {
      let data = this._editor.runCommand('gjs-get-inlined-html');
      this.setToken('gjs-get-inlined-html', data);
      this.setToken('gjs-html', this._editor.runCommand('gjs-get-inlined-html'));
    }
    else {
      this.setToken('gjs-get-inlined-html', '')

    }
  }
  ngOnDestroy() {
    // destroy instance of editor
    if (this._editor) {
      this._editor.destroy();
    }
  }

  /*************************************************************/
  // Initialize Grapesjs 
  /*************************************************************/
  private initializeEditor() {
    return grapesjs.init({
      container: '#gjs',
      fromElement: false,
      autorender: true,
      forceClass: false,
      components: this.emailTemplate.gjsHtml ? this.emailTemplate.gjsHtml : '',
      style: this.emailTemplate.gjsCss ? this.emailTemplate.gjsCss : '',
      colorPicker: {
        appendTo: 'parent',
        offset: { top: 26, left: -166, },
      },
      plugins: ['grapesjs-preset-newsletter'],
      pluginsOpts: {
        'grapesjs-preset-newsletter': {
          inlineCss: true,
          blocksBasicOpts: {
            blocks: []
          }
        }
      },
      assetManager: {
        assest: [],
        uploadText: 'Add image through link or upload image',
        modalTitle: 'Select Image',
        inputPlaceholder: 'http://url/to/the/image.jpg',
        addBtnText: 'Add image',
        uploadFile: (e) => {
          const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
          let fileUpload = new FormData();
          fileUpload.append('file', file);
          this.commonService.callApi(this.callAPIConstants.AdminFileUpload, fileUpload, 'post', false, true).then(success => {
            if (success.status == 1) {

              this._editor.AssetManager.add(success.data.filePath);
            }
          })
        }
      },
      storageManager: {
        autosave: true,
        setStepsBeforeSave: 1,
        autoload: true,
        type: 'local',
        urlStore: '',
        urlLoad: '',
        contentTypeJson: true,
      },
      handleAdd: (textFromInput) => {
        this._editor.AssetManager.add(textFromInput);
      },
      canvas: {
        styles: [
          'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css',
          'https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css'
        ],
        scripts: ['https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js']
      }
    });
  }
  /*************************************************************/

  // get value to insert in editor
  public insertValue: any;
  showValue(event) {
    this.insertValue = event;
  }
}
