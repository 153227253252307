import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { MatPaginatorModule, MatSlideToggleModule, MatTooltipModule, MatButtonModule } from '@angular/material';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms';

// plugin
import { NgSelectModule } from '@ng-select/ng-select';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { PaginationModule, ModalModule, BsDatepickerModule, TabsModule, TooltipModule, } from 'ngx-bootstrap';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxFileDropModule } from 'ngx-file-drop';
import { DataTablesModule } from 'angular-datatables';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
// common
import { BnDatatableModule } from '../../common/bn-datatable/bn-datatable.module'
import { BaseComponent } from '../../common/commonComponent';
import { DragDropDirective } from '../../common/directives/drag-drop.directive';

// Component
import { SubHeaderComponent } from '../sub-header/sub-header.component';
import { DataTableComponent } from '../data-table/data-table.component';
import { ResponsiveDataTableComponent } from '../responsive-data-table/responsive-data-table.component';
import { GrapesJSComponent } from '../../reusable/grapes-js/grapes-js.component';
import { PasswordComponent } from '../../reusable/password/password.component';
import { AddEditAcPcComponent } from '../../reusable/add-edit-ac-pc/add-edit-ac-pc.component';
import { AddEditEmailComponent } from '../../reusable/add-edit-email/add-edit-email.component';
import { DynamicFilterComponent } from '../../reusable/dynamic-filter/dynamic-filter.component';
import { GooglePlacesDirective } from '../../common/directives/google-places.directive';
import { CanCreateActivate, CanEditActivate } from 'src/app/common/auth.gaurd';
@NgModule({
  imports: [
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    TooltipModule.forRoot(),
    NgxFileDropModule,
    MatButtonModule, MatInputModule, MatFormFieldModule, MatSelectModule,
    RouterModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    HttpClientModule,
    FormsModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    NgSelectModule,
    SweetAlert2Module.forRoot(),
    TabsModule.forRoot(),
    MalihuScrollbarModule.forRoot(),
    BnDatatableModule,
    LoadingBarHttpClientModule,
    PaginationModule.forRoot(),
    ImageCropperModule,
    MatTooltipModule,
    DataTablesModule,
    TypeaheadModule.forRoot(),


  ],
  declarations: [
    PasswordComponent,
    BaseComponent,
    SubHeaderComponent,
    DataTableComponent,
    ResponsiveDataTableComponent,
    GrapesJSComponent,
    AddEditAcPcComponent,
    AddEditEmailComponent,
    DynamicFilterComponent,
    GooglePlacesDirective,
    DragDropDirective,
    CanCreateActivate,
    CanEditActivate

  ],
  providers: [
    CanCreateActivate,
    CanEditActivate
  ],
  exports: [
    NgxFileDropModule,
    CalendarModule,
    MatButtonModule, MatInputModule, MatFormFieldModule, MatSelectModule,
    BsDatepickerModule,
    TabsModule,
    HttpClientXsrfModule,
    NgxSpinnerModule,
    ModalModule,
    FormsModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    NgSelectModule,
    SweetAlert2Module,
    MalihuScrollbarModule,
    BnDatatableModule,
    LoadingBarHttpClientModule,
    PaginationModule,
    ImageCropperModule,
    TooltipModule,
    // ChartModule,
    MatTooltipModule,
    SubHeaderComponent,
    DataTableComponent,
    ResponsiveDataTableComponent,
    GrapesJSComponent,
    PasswordComponent,
    AddEditAcPcComponent,
    AddEditEmailComponent,
    DynamicFilterComponent,
    GooglePlacesDirective,
    TypeaheadModule,
    DragDropDirective,
    CanCreateActivate,
    CanEditActivate

  ],
})
export class SharedModule { }
