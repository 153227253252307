import { Component, OnInit, Injector, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from 'src/app/common/commonComponent';
import { Observable, of, Subject, concat } from 'rxjs';
import { mergeMap, debounceTime, distinctUntilChanged, tap, switchMap, catchError, delay } from 'rxjs/operators';
@Component({
  selector: 'app-dynamic-filter',
  templateUrl: './dynamic-filter.component.html',
  styles: []
})
export class DynamicFilterComponent extends BaseComponent implements OnInit {
  public filterlist: Array<any> = [];
  constructor(inj: Injector) { super(inj); }
  public statusFormData: any = {};
  public bindLabel: any;
  public bindValue: any;
  public selectedValue: any;
  @Input() fields: any;
  @Output() filterValue = new EventEmitter();
  @Input() type: any;
  public filter: Array<any> = [];
  public ApiParameters: any = {};
  ngOnInit() {
    this.loadTypehead();


  }

  filteredData$: Observable<any>;
  filteredDataInput$ = new Subject<string>();
  filter_typeheadLoading = false
  notFoundText: string; //Message to display when record not found.


  loadTypehead() {
    this.fields.forEach(col => {
      col.filteredData$ = new Observable<any>();
      col.filteredDataInput$ = new Subject<string>();
      col.filter_typeheadLoading = false;
      //1. firstname
      col.filteredData$ = concat(
        of([]), // default items
        col.filteredDataInput$.pipe(
          debounceTime(200),
          distinctUntilChanged(),
          tap((term: any) => {
            if (term) {
              if (term.length > 3) {
                col.filter_typeheadLoading = true
              } else {
                col.filter_typeheadLoading = false
              }
            }
          }),
          mergeMap((term: any) => this.getSearchList(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => col.filter_typeheadLoading = false)
          ))
        )
      );
    });
  }
  selectedColName: any;
  filterChanged(colFieldname, value) {
    if (!value) {
      this.selectedColName = colFieldname;
    }
  }

  getSearchList(term: string = null, ): Observable<any[]> {
    let data: any = {};
    data.searchInFilter = term;
    data.page = 1;
    data.pagesize = 20;
    data.column = this.selectedColName;
    let apiUrl: any

    if (this.type == 'campaignAnalytics') {
      data.isAnalytic = true;
      (data.column == 'QUALIFICATION' ? data.column = 'qualificationName' : data.column == 'ROLE' ?
        data.column = 'role' : data.column = 'MODE' ? data.column = 'mode' : data.column = 'ENTITY TYPE' ? data.column = 'categoryType' : '')
      apiUrl = 'notification/getColumnValues';
    }
    else if (this.type == 'CsvList') {
      apiUrl = 'csvfile/getColumnValues';
      data._id = this.getToken('adminUserId');
      data.column = (data.column == 'AUTHORISED CENTRE' ? data.column = 'authCentreName' : data.column == 'PREPARATION CENTRE' ? data.column = 'prepCentreName' : data.column == 'QUALIFICATION' ? data.column = 'csvQualificationName' : data.column == 'Qualification' ? data.column = 'csvQualificationName' : data.column == 'MODE' ? data.column = 'mode' :
        '')
    }
    let items = [];
    if (term && term.length >= 3) {
      this.commonService.callApi(apiUrl, data, 'post').then(success => {
        success.data.listing.forEach(element => {
          items.push(element);
        });
        if (items.length) {
        }
        else {
          this.notFoundText = 'No Records Found';
        }
      })
    } else {
      items = []
      // this.notFoundText = "No Results Found";
    }
    return of(items).pipe(delay(200));
  }
  /*************************************************************
@purpose :To GetFilterList.
/*************************************************************/
  getFilterlistData(colName, event) {
    this.filterlist = [];
    if (colName == 'notificationType') {
      this.filterlist = ['email', 'sms'];
    }
    else if (colName == 'AUTHORISED CENTRE' || colName == 'PREPARATION CENTRE' || colName == 'QUALIFICATION' || colName == 'Qualification' ||
      colName == 'MODE' || colName == 'ROLE' || colName == 'ENTITY TYPE') {
      let data: any = {
        page: 1,
        pagesize: 20,
      }
      data['searchText'] = event.target.value;

      if (colName == 'status') {
        this.filterlist = ['Active', 'Inactive'];
      }
      else {
        let apiUrl: any;
        if (this.type == 'campaignAnalytics') {
          data.isAnalytic = true;
          (colName == 'QUALIFICATION' ? data.column = 'qualificationName' : colName == 'ROLE' ?
            data.column = 'role' : colName == 'MODE' ? data.column = 'mode' : colName == 'ENTITY TYPE' ? data.column = 'categoryType' : "")
          apiUrl = 'notification/getColumnValues';
        }
        else if (this.type == 'CsvList') {
          (colName == 'AUTHORISED CENTRE' ? data.column = 'authCentreName' : colName == 'PREPARATION CENTRE' ? data.column = 'prepCentreName' : colName == 'QUALIFICATION' ?
            data.column = 'csvQualificationName' : colName == 'ENTITY TYPE' ? data.column = 'categoryName' : '')
          apiUrl = 'csvfile/getColumnValues';
          data._id = this.getToken('adminUserId');
        } else if (this.type == 'LeadsList') {
          (colName == 'AUTHORISED CENTRE' ? data.column = 'authCentreName' : colName == 'PREPARATION CENTRE' ? data.column = 'prepCentreName' : colName == 'QUALIFICATION' ?
            data.column = 'csvQualificationName' : colName == 'ENTITY TYPE' ? data.column = 'categoryName' : '')
          apiUrl = 'csvfile/getColumnValues';
          data._id = this.getToken('adminUserId');
        }
        else if (this.type == 'campaignChart') {
          (colName == 'QUALIFICATION' ? data.column = 'qualification' : colName == 'ROLE' ?
            data.column = 'role' : colName == 'MODE' ? data.column = 'type' : colName == 'ENTITY TYPE' ? data.column = 'categoryType' : "")
          data.notificationData = true;
          apiUrl = 'admin/notificationGetColumnValues';
          data._id = this.getToken('adminUserId');
        }
        this.CallAPIgetFilterList(apiUrl, data);
        
        // if (data.column == 'qualification' || data.column == 'qualificationName' || colName == 'QUALIFICATION') {
        //   this.filter.forEach(data1 => {
        //     for (let key in data1) {
        //       if (key == 'ENTITY TYPE' && (data.column == 'qualification' || data.column == 'qualificationName' || data.column == 'csvQualificationName')) {
        //         if (this.type == 'CsvList') {
        //           data['filter'] = [{ categoryName: data1[key] }];
        //         }
        //         else {
        //           data['filter'] = [{ categoryType: data1[key] }];
        //         }
        //         this.CallAPIgetFilterList(apiUrl, data);
        //       }
        //     }
        //   }
        //   )
        // }
        // else {
        //   (data['filter']) ? delete data['filter'] : '';
        //   this.CallAPIgetFilterList(apiUrl, data);

        // }
        // this.CallAPIgetFilterList(apiUrl, data);

      }


    }
  }
  /*************************************************************
@purpose :To GetFilterList Values.
/*************************************************************/
  CallAPIgetFilterList(method, data) {
    this.commonService.callApi(method, data, 'post', false, false).then(success => {
      if (success.status == 1) {
        if (success.data.listing.length) {
          this.filterlist = success.data.listing;

        } else {
          this.notFoundText = 'No Records Found';
        }
      }
    })
  }
  /*************************************************************
@purpose :To SelectFilter.
/*************************************************************/
  selectFilter(fieldName, event) {
    if (fieldName === 'status') {
      if (event === 'Active') {
        this.modifyFilterArr(fieldName, [true]);
      } else if (event === 'Inactive') {
        this.modifyFilterArr(fieldName, [false]);
      } else {
        this.modifyFilterArr(fieldName, [true, false]);
      }
    } else {
      this.modifyFilterArr(fieldName, event);
    }
  }
  /*************************************************************
@purpose :To ModifyFilterArr.
/*************************************************************/
  modifyFilterArr(fieldName, event) {

    if ((fieldName == 'ENTITY TYPE') && !event.length) {
      this.fields.forEach(element1 => {
        if (element1.name == 'QUALIFICATION') {
          element1['value'] = [];
        }
      });
      this.filter.forEach(field => {
        for (let key in field) {
          if (key == 'QUALIFICATION') {
            delete field[key]
          }
        }
      })
    }
    let index = this.filter.findIndex(o => Object.keys(o).includes(fieldName))
    if (index > -1) { this.filter[index][fieldName] = event }
    else {
      this.filter.push({ [fieldName]: event });
    }
    this.ApiParameters.filter = JSON.parse(JSON.stringify(this.filter));
    this.ApiParameters.filter.forEach((data, index) => {
      for (let key in data) {
        if (key === 'AUTHORISED CENTRE' && this.type == 'CsvList') {
          data["authCentreName"] = [];
          data["authCentreName"] = data[key];
          delete data[key]
        }
        else if (key === 'PREPARATION CENTRE' && this.type == 'CsvList') {
          data["prepCentreName"] = [];
          data["prepCentreName"] = data[key];
          delete data[key]
        }
        else if (key === 'PREPARATION CENTRE') {
          data['prepCentreData.centreName'] = [];
          data['prepCentreData.centreName'] = data[key];
          delete data[key]
        }
        else if (key === 'ROLE') {
          data['role'] = [];
          data['role'] = data[key];
          delete data[key]
        }
        else if (key === 'QUALIFICATION' && this.type == 'CsvList') {
          data['csvQualificationName'] = [];
          data['csvQualificationName'] = data[key];
          delete data[key]
        } else if (key === 'QUALIFICATION' && this.type == 'campaignAnalytics') {
          data['qualificationName'] = [];
          data['qualificationName'] = data[key];
          delete data[key]
        } else if (key === 'MODE' && this.type == 'campaignAnalytics') {
          data['mode'] = [];
          data['mode'] = data[key];
          delete data[key];
        } else if (key === 'MODE' && this.type == 'campaignChart') {
          data['type'] = [];
          data['type'] = data[key];
          delete data[key];
        } else if (key === 'QUALIFICATION' && this.type == 'campaignChart') {
          data['qualification'] = [];
          data['qualification'] = data[key];
          delete data[key];
        }
        else if (key === 'ENTITY TYPE' && this.type != 'CsvList') {
          data['categoryType'] = [];
          data['categoryType'] = data[key];
          delete data[key];
        }
        else if (key === 'ENTITY TYPE' && this.type == 'CsvList') {
          data['categoryName'] = [];
          data['categoryName'] = data[key];
          delete data[key];
        }
      }
    })
    this.filterValue.emit(this.ApiParameters.filter);
  }

}
