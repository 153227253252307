import { AfterViewInit, ChangeDetectorRef, Component, Injector, Input, NgZone, Inject, OnInit, TemplateRef, ViewChild, ChangeDetectionStrategy, ElementRef, Output, EventEmitter } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import Responsive from 'datatables.net-responsive';
import { BsModalRef } from 'ngx-bootstrap';
import { Observable, of, Subject, concat } from 'rxjs';
import { mergeMap, debounceTime, distinctUntilChanged, tap, switchMap, catchError, delay } from 'rxjs/operators';
import { AddEditcsvComponent } from 'src/app/modals/add-editcsv/add-editcsv.component';
import { BaseComponent } from '../../common/commonComponent';
import { DOCUMENT } from '@angular/common';
import { ViewNotificationComponent } from 'src/app/modals/view-notification/view-notification.component';
import { SelectCsvLeadsComponent } from 'src/app/modals/select-csv-leads/select-csv-leads.component'
import { SendStudentNotificationsComponent } from 'src/app/modals/send-student-notifications/send-student-notifications.component';
@Component({
  selector: 'app-responsive-data-table',
  templateUrl: './responsive-data-table.component.html',
  styles: [],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResponsiveDataTableComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('customPagination') customPagination: any;
  public count: number = 0;
  public itemsPerPageList: Array<any> = [];
  public filteredResponse: any = {};
  public entityTypeSelected: boolean = false;
  selectedFileList: any = [];
  constructor(inj: Injector, public cd: ChangeDetectorRef, public zone: NgZone, @Inject(DOCUMENT) private document: any) {
    super(inj);
    this.APIparameters = { page: 1, pagesize: 10 };
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate());
    this.MainSearchdataSource = Observable.create((observer: any) => {
      observer.next(this.searchKey);
    }).pipe(mergeMap((value: string) => {
      if (this.setFocus) {
        this.searchLisitng(value);
      }
      this.setFocus = true;
      return [];
    }));
  }
  public studentUpdateBroadCaster: any;
  public currentPage = 1;
  dtOptions: any = {};
  // dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  APIparameters: any = {};
  total: any;
  public type: any;
  public submitted = false;
  public selectedUserList: any[] = [];
  public columnVisibilityOptions = false;
  public filterColumnOption = false;
  public params: any;
  public conditions: any;
  public statusArr: any[] = [{ name: 'Active', value: true }, { name: 'inactive', value: false }];
  // tslint:disable-next-line:no-input-rename
  @Input('tableSetupData') tableSetupData: any = {};
  @Input('accessPermission') accessPermission: any = {};
  public tempData: any = [];
  public tableConfig: any;
  public statusFormData = { status: '' };
  public statusList: any[];
  public itemsPerPage = 10;
  public columnVisibleArr: any[] = [];
  public searchKey: any;
  public noText: any;
  public editorData: any;
  public tableData: any = {};
  public fields: any = [];
  public filterlist: any[] = [];
  public filter = [];
  public filterToSave = {};
  public dataOfSaveFilter: any;
  public MainSearchdataSource: Observable<any>;
  public setFocus = true;
  public maxDate: any;
  public modalRef: BsModalRef;
  public isFilterApplied: boolean = true;
  @Input() CSVLeadData;

  /*************************************************************
 @purpose :Common Api List
  /*************************************************************/
  public getMethodName: any;
  public CSV: any;
  public EXCEL: any;
  public DeleteUser: any;
  public ChangeStatus: any;
  public getFilterlist: any;
  public colVisible: any;
  public saveFilter: any;
  public getFilter: any;
  public deleteFilter: any;

  ngOnInit() {
    this.tableConfig = this.tableSetupData;
    this.loadTypehead();
    this.type = this.tableConfig.type;
    this.noText = this.tableConfig.noText;
    this.params = this.tableConfig.params;
    this.conditions = this.tableConfig.conditions;
    if (this.type === 'campaignAnalytics') {
      this.itemsPerPageList = [5, 10];
    } else {
      this.itemsPerPageList = [5, 10, 25, 50, 100];
      if (this.type == 'examList') {
      }
      this.APIparameters = { page: 1, pagesize: 10, qualificationScheduleDate: this.tableConfig.date };
    }
    /**************************************************************
     @Purpose :To Update the listing of students.
   //************************************************************** */
    this.studentUpdateBroadCaster = this.broadcaster.on<any>('studentUpdated').subscribe((success) => {
      if (success) {
        this.callApiToGetData();
      }
    });
  }

  ngAfterViewInit() {
    this.callApiToGetData();

    /*************************************************************
     @purpose :DataTable options.
    /*************************************************************/
    this.dtOptions = {
      destroy: true,
      retrieve: true,
      searching: false,
      sorting: false,
      ordering: false,
      autoWidth: false,
      paging: false,
      info: false,
      responsive: true,
      columnDefs: [
        { responsivePriority: 1, targets: 0 },
        { responsivePriority: 2, targets: -1 }
      ]
    };
  }

  filteredData$: Observable<any>;
  filteredDataInput$ = new Subject<string>();
  filter_typeheadLoading = false
  notFoundText: string = "Type at least " + 3 + " characters." //Message to display when record not found.


  loadTypehead() {
    this.tableConfig.cols.forEach(col => {
      col.filteredData$ = new Observable<any>();
      col.filteredDataInput$ = new Subject<string>();
      col.filter_typeheadLoading = false;
      //1. firstname
      col.filteredData$ = concat(
        of([]), // default items
        col.filteredDataInput$.pipe(
          debounceTime(200),
          distinctUntilChanged(),
          tap((term: any) => {
            if (term) {
              if (term.length > 3) {
                col.filter_typeheadLoading = true
              } else {
                col.filter_typeheadLoading = false
              }
            }
          }),
          switchMap((term: any) => this.getSearchList(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => col.filter_typeheadLoading = false)
          ))
        )
      );
    });
  }
  selectedColName: any;
  filterChanged(colFieldname, value) {
    if (!value) {
      this.selectedColName = colFieldname;
    }
  }

  getSearchList(term: string = null): Observable<any[]> {
    let data: any = {};
    data.searchText = term;
    data.page = 1;
    data.pagesize = 20;
    data.column = this.selectedColName;
    (this.type == 'EmailList') ? data.createdBy = this.getToken('adminUserId') : "";
    (this.type == 'CsvDetail') ? data._id = this.tableSetupData.id : "";
    (this.type == 'CsvList') ? data._id = this.getToken('adminUserId') : "";
    (this.type == 'campaignAnalytics') ? data.isAnalytic = true : "";
    if (this.type == 'TemplateApproval') {
      data.status = this.tableSetupData.status;
      data.filter = this.tableSetupData.filter;
    }
    let items = [];
    if (term && term.length > 3) {
      this.commonService.callApi(this.getFilterlist, data, 'post').then(success => {
        success.data.listing.forEach(element => {
          items.push(element);
        });
        if (items.length) {
        }
        else {
          this.notFoundText = 'No Records Found';
        }
      })
    } else {
      items = []
      // this.notFoundText = "No Results Found";
    }
    return of(items).pipe(delay(200));
  }

  /**************************************************************
  @Purpose :To Call the Apis to getData Dynamicaly.
  //************************************************************** */

  callApiToGetData() {
    if (this.type) {
      if (this.type == 'userlist') {
        this.getMethodName = this.callAPIConstants.UserList;
        this.colVisible = this.callAPIConstants.UserColumnVisibleSettings;
        this.getFilterlist = this.callAPIConstants.UserGetColumnData;
        this.saveFilter = this.callAPIConstants.UserSaveFilter;
        // this.getFilter = this.callAPIConstants.UserGetFilters;
        this.deleteFilter = this.callAPIConstants.UserDeleteFilter;
      }
      // Conditions for AuthorisedCentre List And Search
      else if (this.type == 'AcList') {
        if (this.APIparameters.searchText) {
          this.getMethodName = this.callAPIConstants.AUTHCENTRESEARCH;
          delete this.APIparameters.filter
        } else {
          this.getMethodName = this.callAPIConstants.ACLIST;
        }
        this.colVisible = this.callAPIConstants.AUTHCENTRECOLUMNSETTINGS;
        this.getFilterlist = 'authcentre/getColumnValues';
        this.saveFilter = 'authcentre/saveFilter';
        this.getFilter = 'authcentre/getFilters';
        this.deleteFilter = 'authcentre/deleteFilter/';
        this.CSV = 'admin/authCentreCsvExport';
        this.EXCEL = 'admin/authCentreCsvExport'
      }
      else if (this.type == 'csvLeadsList' || this.type === 'csvLeadsList1') {
        this.getMethodName = this.callAPIConstants.CSVLeadUsers;
        this.APIparameters.categoryType = this.CSVLeadData.categoryType;
        this.APIparameters.qualification = this.CSVLeadData.qualification;
        this.getFilterlist = 'users/getColumnValues';
      }
      else if (this.type == 'seletedLeadData') {
        this.getMethodName = this.callAPIConstants.seletedLeadData;
        this.APIparameters.qualification = this.tableSetupData.id;
        this.APIparameters.role = 'admin';
      }

      // Conditions for PreparationCentre List And Search
      else if (this.type === 'PcList') {
        // setTimeout(() => {
        //   this.fields = [{ name: 'region' }, { name: 'country' }];
        // });
        if (this.APIparameters.searchText) {
          this.getMethodName = this.callAPIConstants.PREPCENTRESEARCH;
          delete this.APIparameters.filter

        } else {
          this.getMethodName = this.callAPIConstants.PCLIST;
        }
        this.colVisible = 'prepcentre/columnSettings';
        this.getFilterlist = 'prepcentre/getColumnValues';
        this.saveFilter = 'prepcentre/saveFilter';
        this.getFilter = 'prepcentre/getFilters';
        this.deleteFilter = 'prepcentre/deleteFilter/';
        this.CSV = 'admin/prepCentreCsvExport';
        this.EXCEL = 'admin/prepCentreCsvExport';
      }
      else if (this.type == 'PcDetails') {
        this.getMethodName = 'admin/getPrepCentreExamDetail';
        this.APIparameters.prepcentreId = this.tableSetupData.id;
      }
      // Conditions for Csv Files List And Search
      else if (this.type == 'CsvList') {
        setTimeout(() => {
          if (!this.fields.length) {
            this.fields = [{ name: 'AUTHORISED CENTRE' }, { name: 'PREPARATION CENTRE' }, { name: 'ENTITY TYPE' }, { name: 'QUALIFICATION' }];
          }
        });
        if (this.APIparameters.searchText) {
          this.APIparameters._id = this.getToken('adminUserId');
          this.getMethodName = 'csvfile/csvFileSearch';
          delete this.APIparameters.filter
        } else {
          this.getMethodName = this.callAPIConstants.CSVLIST;
          this.APIparameters._id = this.getToken('adminUserId');
        }
        this.colVisible = 'csvfile/columnSettings';
        this.getFilterlist = 'csvfile/getColumnValues';
        this.saveFilter = 'csvfile/saveFilter';
        this.getFilter = 'csvfile/getFilters';
        this.deleteFilter = 'csvfile/deleteFilter/';
      }
      else if (this.type == 'LeadUsers') {
        // setTimeout(() => {
        //   if (!this.fields.length) {
        //     this.fields = [{ name: 'AUTHORISED CENTRE' }, { name: 'PREPARATION CENTRE' }, { name: 'ENTITY TYPE' }, { name: 'QUALIFICATION' }];
        //   }
        // });
        if (this.APIparameters.searchText) {
          this.APIparameters._id = this.tableSetupData.id;;
          this.getMethodName = '/csvleadfile/csvLeadUserSearch';
          delete this.APIparameters.filter;
        } else {
          this.getMethodName = this.callAPIConstants.LEADUSERSLIST;
          this.APIparameters._id = this.tableSetupData.id;;
        }
        this.colVisible = 'csvleadfile/columnSettings';
        this.getFilterlist = this.callAPIConstants.LeadsFilterList;
        this.saveFilter = 'csvleadfile/saveFilter';
        this.getFilter = 'csvleadfile/getFilters';
        this.deleteFilter = 'csvleadfile/deleteFilter/';
      }
      else if (this.type == 'LeadsList') {
        // setTimeout(() => {
        //   if (!this.fields.length) {
        //     this.fields = [{ name: 'AUTHORISED CENTRE' }, { name: 'PREPARATION CENTRE' }, { name: 'ENTITY TYPE' }, { name: 'QUALIFICATION' }];
        //   }
        // });
        if (this.APIparameters.searchText) {
          this.APIparameters._id = this.getToken('adminUserId');
          this.getMethodName = this.callAPIConstants.LEADSLIST;
          delete this.APIparameters.filter;
        } else {
          this.getMethodName = this.callAPIConstants.LEADSLIST;
          // this.APIparameters._id = this.getToken('adminUserId');
        }
        this.APIparameters.uploadedBy = this.params.statusParams;
        this.colVisible = 'csvleadfile/columnSettings';
        this.getFilterlist = this.callAPIConstants.LeadsFilterList;
        this.saveFilter = 'csvleadfile/saveFilter';
        this.getFilter = 'csvleadfile/getFilters';
        this.deleteFilter = 'csvleadfile/deleteFilter/';
      }
      // Conditions for CsvDetails List And Search
      else if (this.type === 'CsvDetail') {
        this.APIparameters._id = this.tableSetupData.id;
        if (this.APIparameters.searchText) {
          this.getMethodName = 'csvfile/csvUserSearch';
          delete this.APIparameters.filter

        } else {
          this.getMethodName = this.callAPIConstants.STUDENTSCSVLIST;
        }
        this.colVisible = 'csvfile/getCsvUserColumnValue';
        this.getFilterlist = 'users/getColumnValues';
        this.saveFilter = 'users/saveFilter';
        this.getFilter = 'users/getFilters';
        this.deleteFilter = 'users/deleteFilter/';
      }
      // Conditions for TemplateApproval List And Search

      else if (this.type == 'TemplateApproval') {
        // this.APIparameters.status = this.tableSetupData.status;
        if (this.APIparameters.filter && !this.APIparameters.searchText && !this.APIparameters.filter.length) {
          this.APIparameters.filter = this.tableSetupData.filter;
        }
        else {
          if (this.APIparameters.filter) {
            this.APIparameters.filter.forEach(element => {
              for (const key in element) {
                if (key == 'approvalStatus' && this.count == 1) {
                  this.count++;
                  this.APIparameters.filter.push(this.tableSetupData.filter[0]);
                }
              }
            });
          } else {
            this.APIparameters.filter = this.tableSetupData.filter;
          }
        }
        if (this.APIparameters.searchText) {
          this.getMethodName = this.callAPIConstants.EMAILSEARCH;
          delete this.APIparameters.filter;
          this.APIparameters.approvalStatus = this.tableSetupData.status;
        } else {
          if (this.tableSetupData.status == 'Pending') {

            this.getMethodName = 'notification/listPendingTemplate';
          }
          else {
            this.getMethodName = 'notification/listApproveTemplate';

          }
        }
        this.colVisible = 'notification/columnSettings';
        this.getFilterlist = 'notification/getColumnValues';
        this.saveFilter = 'notification/saveFilter';
        this.getFilter = 'notification/getFilters';
        this.deleteFilter = 'notification/deleteFilter/';
        this.DeleteUser = 'notification/deleteNotificationTemplate';
      }
      // Conditions for Qualification List And Search
      else if (this.type == 'qualificationList') {
        if (this.APIparameters.searchText) {
          this.getMethodName = 'admin/qualificationSearch';
          delete this.APIparameters.filter

        } else {
          this.getMethodName = this.callAPIConstants.QUALIFICATIONLIST;
        }
        this.colVisible = 'qualification/columnSettings';
        this.getFilterlist = 'qualification/getColumnValues';
        this.saveFilter = 'qualification/saveFilter';
        this.getFilter = 'qualification/getFilters';
        this.deleteFilter = 'qualification/deleteFilter/';
        this.DeleteUser = '/admin/deleteQualification'
      }
      // Conditions for Student/Parent List And Search

      else if (this.type == 'Student/ParentList') {
        this.colVisible = 'students/columnSettings';
        this.getFilterlist = 'students/getColumnValues';
        this.saveFilter = 'students/saveFilter';
        this.getFilter = 'students/getFilters';
        this.deleteFilter = 'students/deleteFilter/';
        this.CSV = 'admin/studentParentsCsvExport';
        this.EXCEL = 'admin/studentParentsCsvExport';
        if (this.APIparameters.searchText) {
          this.getMethodName = 'admin/studentParentsSearch';
          delete this.APIparameters.filter

        } else {
          this.getMethodName = 'admin/getStudentsList';
        }
      }
      // Conditions for EmailTemplates List And Search
      else if (this.type == 'EmailList') {
        if (this.APIparameters.searchText) {
          this.getMethodName = this.callAPIConstants.EMAILSEARCH;
          delete this.APIparameters.filter
        } else {
          this.getMethodName = this.callAPIConstants.EMAILLIST;
        }
        // this.APIparameters.isAdminTemplate=true;
        this.APIparameters.recipientType = this.tableSetupData.params.statusParams;
        this.colVisible = 'notification/columnSettings';
        this.getFilterlist = 'notification/getColumnValues';
        this.saveFilter = 'notification/saveFilter';
        this.getFilter = 'notification/getFilters';
        this.deleteFilter = 'notification/deleteFilter/';
        this.DeleteUser = 'notification/deleteNotificationTemplate';
      }
      // Conditions for CMS List And Search

      else if (this.type == 'cmslist') {
        this.getMethodName = this.callAPIConstants.cmsList;
        this.DeleteUser = this.callAPIConstants.cmsDelete;
        this.colVisible = 'cmsColumnSettings';
      }
      else if (this.type == 'LocationList') {
        this.getMethodName = 'admin/listLocations';

      }
      else if (this.type == 'roleList') {
        this.getMethodName = this.callAPIConstants.RoleList;

      }
      // Conditions for SampleCsv List And Search
      else if (this.type == 'SampleCsvList') {
        this.getMethodName = '/csvfile/sampleCsvListing';
        this.DeleteUser = this.callAPIConstants.cmsDelete;
      }
      // bindu
      else if (this.type === 'Sendnotificationlist') {
        this.getMethodName = 'notification/listTemplate';
        // this.APIparameters.createdBy = this.getToken('adminUserId');
        this.colVisible = 'notification/columnSettings';
        this.getFilterlist = 'notification/getColumnValues';
        this.saveFilter = 'notification/saveFilter';
        this.getFilter = 'notification/getFilters';
        this.deleteFilter = 'notification/deleteFilter/';
        this.APIparameters.uploadedBy = this.params.statusParams;

      } else if (this.type === 'Communicationlist') {
        this.getMethodName = 'admin/notificationAnalytics';
        this.colVisible = 'admin/columnSettings';
        this.getFilterlist = 'admin/getColumnValues';
        this.saveFilter = 'admin/saveFilter';
        this.getFilter = 'admin/getFilters';
        this.deleteFilter = 'admin/deleteFilter/';
        // tslint:disable-next-line: no-duplicated-branches
      } else if (this.type === 'campaignAnalyticsList') {
        this.getMethodName = 'admin/notificationAnalytics';
        this.colVisible = 'admin/columnSettings';
        this.getFilterlist = 'admin/getColumnValues';
        this.saveFilter = 'admin/saveFilter';
        this.getFilter = 'admin/getFilters';
        this.deleteFilter = 'admin/deleteFilter/';
      }
      // Conditions for StudentJourney List And Search
      else if (this.type == 'studentJourney') {
        this.getMethodName = 'csvfile/getStudentDetails';
        this.APIparameters.role = 'admin';
        this.APIparameters._id = this.tableSetupData.id;
        this.DeleteUser = this.callAPIConstants.cmsDelete;
      }
      else if (this.type == 'paidRegisteredUserListing') {
        this.getMethodName = this.callAPIConstants.paidRegisteredUserListing;
        this.colVisible = 'admin/columnSettings';
        this.getFilterlist = 'admin/getColumnValues';
        this.saveFilter = 'admin/saveFilter';
        this.getFilter = 'admin/getFilters';
        this.deleteFilter = 'admin/deleteFilter/';
        this.CSV = 'admin/paidRegisteredUserCsvExport';
        this.EXCEL = 'admin/paidRegisteredUserCsvExport';
      }
      else if (this.type == 'NotPaidRegisteredUserListing') {
        this.getMethodName = this.callAPIConstants.NotpaidRegisteredUserListing;
        this.colVisible = 'admin/columnSettings';
        this.getFilterlist = 'admin/getColumnValues';
        this.saveFilter = 'admin/saveFilter';
        this.getFilter = 'admin/getFilters';
        this.deleteFilter = 'admin/deleteFilter/';
        this.CSV = '/admin/notPaidRegisteredUserCsvExport';
        this.EXCEL = '/admin/notPaidRegisteredUserCsvExport';
      }
      else if (this.type == 'CSVRegisteredListing') {
        this.getMethodName = this.callAPIConstants.csvRegisteredUserListing;
        this.colVisible = 'admin/columnSettings';
        this.getFilterlist = 'admin/getColumnValues';
        this.saveFilter = 'admin/saveFilter';
        this.getFilter = 'admin/getFilters';
        this.deleteFilter = 'admin/deleteFilter/';
        this.CSV = '/admin/registeredCsvUserExport';
        this.EXCEL = '/admin/registeredCsvUserExport';
      }
      else if (this.type == 'TobeRegistered') {
        this.getMethodName = this.callAPIConstants.TobeRegisteredUserListing;
        this.colVisible = 'admin/columnSettings';
        this.getFilterlist = 'admin/getColumnValues';
        this.saveFilter = 'admin/saveFilter';
        this.getFilter = 'admin/getFilters';
        this.deleteFilter = 'admin/deleteFilter/';
        this.CSV = '/admin/toBeRegisteredCsvExport';
        this.EXCEL = '/admin/toBeRegisteredCsvExport';
      }
      else if (this.type == 'adminUserlist') {
        this.getMethodName = this.callAPIConstants.AdminUserlist;
        this.colVisible = this.callAPIConstants.AdminColumnVisibleSettings;
        this.getFilterlist = this.callAPIConstants.AdminGetColumnData;
        this.saveFilter = this.callAPIConstants.AdminSaveFilter;
        this.getFilter = this.callAPIConstants.AdminGetFilters;
        this.deleteFilter = this.callAPIConstants.AdminDeleteFilter;
        this.CSV = this.callAPIConstants.AdminCSVforDataTable;
        this.EXCEL = this.callAPIConstants.AdminEXCELforDataTable;
      }
      // Conditions for CampainAnalytics List And Search
      else if (this.type == 'campaignAnalytics') {
        setTimeout(() => {
          this.type = this.tableSetupData.type;
        });
        this.colVisible = 'notification/columnSettings';
        this.getFilterlist = 'notification/getColumnValues';
        this.saveFilter = 'notification/saveFilter';
        this.getFilter = 'notification/getFilters';
        this.deleteFilter = 'notification/deleteFilter/';
        this.APIparameters.uploadedBy = this.params.statusParams;
        if (this.APIparameters.searchText) {
          this.getMethodName = this.callAPIConstants.EMAILSEARCH;
          delete this.APIparameters.filter
        } else {
          this.getMethodName = 'notification/massEmailNotificationAnalytics';
        }

      }

      else if (this.type == 'examList') {
        this.DeleteUser = '/exam/deleteExam';
        if (this.APIparameters.searchText) {
          this.getMethodName = this.callAPIConstants.EMAILSEARCH;
          delete this.APIparameters.filter
        } else {
          this.getMethodName = this.callAPIConstants.LISTEXAMS;
        }
      }
      else if (this.type == 'StudentExamsList') {
        this.APIparameters.qualificationId = this.tableSetupData.id;
        this.APIparameters.qualificationModeType = 'Global';
        if (this.APIparameters.searchText) {
          this.getMethodName = this.callAPIConstants.EMAILSEARCH;
          delete this.APIparameters.filter
        } else {
          this.getMethodName = this.callAPIConstants.ACLIST;
        }
      }


      if (this.getMethodName) {
        // Calling API for getting dynamicData.
        this.commonService.callApi(this.getMethodName, this.APIparameters, 'post', false, false).then((success) => {
          if (this.getMethodName) {
            if (success.status == 1) {
              if (this.type != 'PcDetails') {
                this.tableData.data = success.data.listing;
              }
              // Binding of columnSettings.
              this.tableConfig.cols.forEach((element) => {
                if (success.data.columnSettings) {
                  success.data.columnSettings.forEach((dataApi) => {
                    if (element.colFieldname === dataApi.key) {
                      element.isVisible = dataApi.status;
                    }
                  });
                }
              });
              // Conditions for TemplateApproval List.
              if (this.type == 'TemplateApproval') {
                for (let index = 0; index < this.tableData.data.length; index++) {
                  if (this.tableData.data[index].approvalStatus == 'Pending') {
                    this.tableData.data[index].actions = [
                      { id: '4', buttonTitle: 'Edit', class: 'fa fa-check green', type: 'icon', permission: this.accessPermission.update },
                      { id: '5', buttonTitle: 'Delete', class: 'fa fa-times text-danger', type: 'icon', permission: this.accessPermission.update },
                      { id: '2', buttonTitle: 'Edit', class: 'fa fa-pencil', type: 'icon', permission: this.accessPermission.update },
                    ];
                  } else if (this.tableData.data[index].approvalStatus == 'Rejected') {
                    this.tableData.data[index].actions = [
                      { id: '6', buttonTitle: 'Delete', class: 'fa fa-check green', type: 'icon', permission: this.accessPermission.update },
                      { id: '2', buttonTitle: 'Edit', class: 'fa fa-pencil', type: 'icon', permission: this.accessPermission.update },
                      { id: '3', buttonTitle: 'Delete', class: 'fa fa-trash text-danger', type: 'icon', permission: this.accessPermission.delete }

                    ];
                  } else {
                    this.tableData.data[index].actions = [
                      { id: '1', buttonTitle: 'View', class: 'fa fa-eye', type: 'icon', },
                      { id: '10', buttonTitle: 'Delete', class: 'fa fa-trash text-danger', type: 'icon', permission: this.accessPermission.delete }];
                  }
                }
              }
              // Conditions for CSV to disaply the years and studentJourneydata in table.
              if (this.type === 'CsvDetail' || this.type == 'CsvList' || this.type == 'studentJourney' || this.type == 'LocationList' || this.type == 'CSVRegisteredListing') {
                this.tableData.data.forEach((element) => {
                  if (element.location) {
                    let locationArr = [];
                    locationArr = element.location.split(',')
                    element.country = locationArr[2];
                    element.state = locationArr[1];
                    element.city = locationArr[0];

                  }
                  if (element.startYear && element.endYear) {
                    element.year = element.startYear + '-' + element.endYear
                  }
                  if (element.lastEndYear && element.lastStartYear) {
                    element.year = element.lastStartYear + '-' + element.lastEndYear;
                  }
                  if (element.studentJourney) {
                    element.qualificationName = element.studentJourney[0].qualificationName
                  }
                  if (element.examData) {

                    // this.tableData.data = element.examData;
                  }
                  (element.csvCentreRole) ? (element.csvCentreRole == 'Authorised_Centre' ? element.csvCentreRole = 'Authorised Centre' : element.csvCentreRole = 'Preparation Centre') : '-';
                  (element.centreDataRole) ? (element.centreDataRole == 'Authorised_Centre' ? element.centreDataRole = 'Authorised Centre' : element.csvCentreRole = 'Preparation Centre') : '-';
                  // (element.csvCentreRole) ? (element.csvCentreRole = element.csvCentreRole.replace('_', ' ')) : '-';
                });
              }
              if (this.type == 'LeadsList') {
                this.tableData.data.forEach(ele => {
                  if (ele.csvCentreRole === 'Admin') {
                    ele.csvCentreName = 'Cambridge Admin';
                  }
                  else {
                    ele.csvCentreRole = ele.csvCentreRole.replace('_', ' ');
                  }
                })
              }
              this.total = success.total;
              this.rerenderDataTable();
            }
            else {
              this.popToast('error', success.message);
              if (this.BsModalRef) {
                this.BsModalRef.hide();
              }
            }
          } else {
            this.popToast('error', 'Something Went Wrong!');
          }
        });
      }
    }
  }
  // *************************************************************//
  public download: any = {};
  public isFilter: boolean;
  CallAPIdownloadCSVandExcel(data) {
    data == 'csv' ? this.download.isCsv = true : this.download.isCsv = false;
    data === 'csv' ?
      this.commonService.callApi(this.CSV, this.download, 'post', false, false).then((success) => {
        window.open(success.data, '_blank');
      })
      :
      this.commonService.callApi(this.EXCEL, this.download, 'post', false, false).then((success) => {
        window.open(success.data, '_blank');
      });
  }
  /*************************************************************
  @purpose :Rerendering Datatable
  /*************************************************************/
  rerenderDataTable() {
    if (this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        if (this.tableData.data.length || this.APIparameters.searchText || this.APIparameters.filter) {
          dtInstance.destroy();
          this.dtTrigger.next();
        }
      });
    } else {
      this.dtTrigger.next();
    }
  }
  // *************************************************************//

  /*************************************************************
@purpose :  Sorting for user listing
  /*************************************************************/

  onSorted(event) {
    this.APIparameters.sort = {};
    event.sortDirection === 'desc' ? event.sortDirection = -1 : event.sortDirection = 1;
    this.APIparameters.sort[event.sortColumn.colFieldname] = event.sortDirection;
    this.callApiToGetData();
  }
  // *************************************************************//

  /***************************************************************
@Purpose : Pagination //
  /***************************************************************/
  pagination(e) {
    this.APIparameters.page = e.page;
    this.itemsPerPage = e.itemsPerPage;
    this.selectedUserList = [];
    this.tableConfig.cols.map(x => {
      x.checkbox = false;
    })
    this.callApiToGetData();
  }
  /***************************************************************
 @Purpose : Range Change //
   /***************************************************************/
  rangeChanged(e) {
    this.customPagination._page = 1;
    this.APIparameters.pagesize = e;
    this.APIparameters.page = 1;
    this.selectedUserList = [];
    this.tableConfig.cols.map(x => {
      x.checkbox = false;
    })
    this.callApiToGetData();
  }
  /***************************************************************/

  /***************************************************************
@Purpose : Perform Action: VIEW, EDIT, DELETE ,STATUS //
  /***************************************************************/
  public sample = [];
  performAction(type?, k?, i?) {
    // VIEW action
    this.sample.push(k);
    if ($(event.target).parents('td').hasClass('status-dropdown') && !$(event.target).hasClass('status-dropdown')) {
      if (k.id === '1') {
        if (this.type === 'EmailList' || this.type === 'TemplateApproval') {
          this.BsModalRef = this.modalService.show(ViewNotificationComponent,
            { class: 'modal-md modal-dialog-centered', initialState: { data: i } });
        } else {
          this.router.navigate([this.URLConstants.USERS, i._id]);
        }

      }
      // Conditon For Edit

      else if (k.id === '2' && (type == 'action' || type == 'statusActions')) {
        if (this.type == 'adminUserlist') {
          this.router.navigate([this.URLConstants.ADMINUSER_DETAIL, i._id]);
        } else if (this.type === 'emailTemplateList') {
          this.router.navigate([this.URLConstants.EMAIL_TEMPLATE_detail, i._id]);
        } else if (this.type === 'cmslist') {
          this.router.navigate([this.URLConstants.CMS_DETAIL, i._id, i.pageId]);
        } else if (this.type === 'roleList') {
          this.router.navigate([this.URLConstants.MANAGE_ROLES_DETAIL, i._id]);
        } else if (this.type === 'AcList') {
          this.router.navigate([this.URLConstants.AUTHDETAIL, i._id]);
        } else if (this.type === 'PcList') {
          this.router.navigate([this.URLConstants.PREPARATIONDETAIL, i._id]);
        } else if (this.type === 'EmailList') {
          this.router.navigate([this.URLConstants.EMAILDETAIL, i._id]);
        } else if (this.type === 'Student/ParentList') {
          this.router.navigate([this.URLConstants.PREPARATIONDETAIL, i._id]);
        } else if (this.type === 'qualificationList') {
          this.router.navigate([this.URLConstants.QUALIFICATIONDETAIL, i._id]);
          // this.broadcaster.broadcast('getDetails',i._id);
        } else if (this.type === 'TemplateApproval') {
          this.router.navigate([this.URLConstants.EMAILAPPROVALDETAIL, i._id, this.accessPermission.update]);
        }
        else if (this.type === 'CsvDetail' || this.type == 'paidRegisteredUserListing') {
          this.router.navigate([this.URLConstants.CSVSTUDENTDETAILS, i._id, i._id]);
        }
        else if (this.type === 'CsvDetail') {
          this.BsModalRef = this.modalService.show(AddEditcsvComponent,
            { class: 'modal-lg modal-dialog-centered', initialState: { data: i._id } });
        }
        else if (this.type === 'Sendnotificationlist' || this.type === 'seletedLeadData') {

          this.BsModalRef = this.modalService.show(ViewNotificationComponent,
            { class: 'modal-md modal-dialog-centered', initialState: { data: i } });
        }
        else if (this.type === 'examList') {
          this.router.navigate([this.URLConstants.EXAMSDTEAILS, i._id]);
        }
      }
      // Conditon For Download
      else if (k.id == 'download') {
        let data: any = {
          categoryType: i.csvCategoryId
        }
        this.commonService.callApi('csvfile/sampleCsvDownload', data, 'post').then(success => {
          if (success.status == 1) {
            this.document.location.href = success.downloadLink;
          }
        })
      }
      else if (k.id == 'view') {
        this.router.navigate([this.URLConstants.STUDENTEXAMS, k.id]);
      }
      else if (k.id == '3') {
        this.swal({
          text: `Do you want to Delete the Rejected Template`,
          type: 'question',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-primary',
          cancelButtonClass: 'btn btn-info',
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        }).then((result) => {
          const apiData = i;
          if (result.value) {
            apiData._id = i._id;
            this.commonService.callApi(this.callAPIConstants.DELETETEMPLATE, apiData, 'post').then((success) => {
              if (success.status === 1) {
                this.popToast('success', 'Template Deleted');
                this.callApiToGetData();
              } else {
                this.popToast('error', success.message);
              }
            });
          } else {
          }
        });
      } else if (k.id == '10') {
        this.swal({
          text: `Do you want to Delete?`,
          type: 'question',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-primary',
          cancelButtonClass: 'btn btn-info',
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        }).then((result) => {
          const apiData = i;
          if (result.value) {
            if (this.type === 'qualificationList') {
              apiData.qualificationId = i._id;
            } else if (this.type === 'examList') {
              apiData.examId = i._id;
            } else {
              apiData._id = i._id;
            }
            this.commonService.callApi(this.DeleteUser, apiData, 'post').then((success) => {
              if (success.status === 1) {
                this.popToast('success', success.message);
                if (this.type === 'examList') {
                  location.reload();
                }
                else {
                  this.callApiToGetData();
                }

              } else {
                this.popToast('error', success.message);
              }
            });
          } else {
          }
        });
      }
      // Conditon For ChangeStatus of Templates to approve,reject
      else if (k.id === '4' || k.id === '5' || k.id === '6') {
        let text: any;
        ((k.id === '4') ? text = 'Pending to Approved' : (k.id === '5') ?
          text = 'Pending to Rejected' : (k.id === '6') ? text = 'Rejected to Approved' : text = '');
        this.swal({
          text: `Do you want to change the status of this from ${text}`,
          type: 'question',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-primary',
          cancelButtonClass: 'btn btn-info',
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        }).then((result) => {
          const apiData = i;
          if (result.value) {
            if (k.id === '4' || k.id === '6') {
              apiData.approvalStatus = 'Approved';
              apiData._id = i._id;
            } else if (k.id === '5') {
              apiData.approvalStatus = 'Rejected';
              apiData._id = i._id;
            }
            this.commonService.callApi(this.callAPIConstants.ADDUPDATETEMPLATE, apiData, 'post').then((success) => {
              if (success.status === 1) {
                this.popToast('success', success.message);
                this.callApiToGetData();
              } else {
                this.popToast('error', success.message);
              }
            });
          } else {
          }
        });
      }
    }
    // To view Details of the listings.
    else {
      if (this.type == 'PcList') {
        this.router.navigate([this.URLConstants.PREPARATIONDETAIL, i._id]);
        // this.router.navigate([this.URLConstants.prepDetails])
      } else if (this.type === 'PcDetail') {
        this.router.navigate([this.URLConstants.STUDENTQUALIFICATIONLIST, i._id]);
      } else if (this.type === 'CsvList') {
        this.router.navigate([this.URLConstants.CSVSTUDENTLIST, i._id]);
      } else if (this.type === 'CsvDetail' || this.type == 'paidRegisteredUserListing') {
        this.router.navigate([this.URLConstants.CSVSTUDENTDETAILS, i._id]);
      } else if (this.type === 'roleList') {
        this.router.navigate([this.URLConstants.MANAGE_ROLES_DETAIL, i._id]);
      }
      else if (this.type === 'Student/ParentList') {
        this.router.navigate([this.URLConstants.STUDENTDETAILS, i._id]);
      }

    }
    $('body').hasClass('modal-open') ? '' : this.sample = [];
  }


  /***************************************************************/

  /***************************************************************
    @Purpose :change visibility of columns
  /***************************************************************/
  tooglecolumnVisibilityFilter() {
    this.columnVisibilityOptions = !this.columnVisibilityOptions;
    this.filterColumnOption = false;
  }
  /***************************************************************
    @Purpose :To change visibility of columns
  /***************************************************************/
  CallAPIchangeColumnVisibility(tableConfigCols) {
    tableConfigCols.forEach((element) => {
      if (element.columnVisibility) {
        const data = { key: element.colFieldname, status: element.isVisible };
        this.columnVisibleArr.push(data);
      }
    });
    const data: any = { columns: this.columnVisibleArr };
    if (this.type == 'TemplateApproval') {
      data.status = this.tableSetupData.status;
    }
    else if (this.type == 'campaignAnalytics') { data.isAnalytic = true; }
    else if (this.type == 'campaignAnalyticsList') { data.isAdminAnalytic = true; }
    else if (this.type == 'Communicationlist') { data.isAdminCommunication = true; }
    else if (this.type == 'LeadUsers') { data.isLeadUser = true; }
    this.commonService.callApi(this.colVisible, data, 'post', false, false).then((success) => {
      success.status === 1 ? this.popToast('success', 'Updated Successfully') : this.popToast('error', success.message);
      this.columnVisibleArr = [];
      this.callApiToGetData();
    });
  }
  /***************************************************************
    @Purpose :To Select All.
  /***************************************************************/
  selectAllColumnVisible() {
    this.tableConfig.cols.forEach((element) => {
      element.isVisible = true;
      element.disable = false;
    });
  }
  /***************************************************************
    @Purpose :To Reset Columns.
  /***************************************************************/
  resetColumnVisible() {
    this.callApiToGetData();
  }

  /***************************************************************
    @Purpose :To Get SavedFilters.
  /***************************************************************/
  toggleFilterColumn() {
    this.filterColumnOption = !this.filterColumnOption;
    this.columnVisibilityOptions = false;
    this.tableConfig.cols.forEach(element => {
      if (element.colName == 'Qualification') {
        element['disabled'] = true;
      }
    });
    if (this.filterColumnOption) {
      this.CallAPIgetSavedFilter();
    }

  }
  // notFoundText: string  //Message to display when record not found.

  /***************************************************************
   @Purpose :To Get FilteredList.
  /***************************************************************/
  getFilterlistData(colName, event?) {
    this.filterlist = [];
    if (colName == 'templateType') {
      this.filterlist = ['promotions', 'updates'];
    }
    else if (colName == 'type') {
      this.filterlist = ['text', 'email'];
    }
    else {
      this.filteredResponse.page = 1;
      this.filteredResponse.pagesize = this.APIparameters.pagesize,
        this.filteredResponse.column = colName,
        this.filteredResponse['searchInFilter'] = event.target.value;
      ((this.type == 'EmailList') ? this.filteredResponse.createdBy = this.getToken('adminUserId') : '');
      ((this.type == 'CsvDetail') ? this.filteredResponse._id = this.tableSetupData.id : '');
      ((this.type == 'CsvList' || this.type == 'LeadsList') ? this.filteredResponse._id = this.getToken('adminUserId') : '');
      ((this.type == 'campaignAnalytics') ? this.filteredResponse.isAnalytic = true : '');
      if (this.type == 'campaignAnalytics') { this.filteredResponse.isAnalytic = true; };
      if (this.type == 'campaignAnalyticsList') { this.filteredResponse.isAdminAnalytic = true; };
      if (this.type == 'Communicationlist') { this.filteredResponse.isAdminCommunication = true; };
      // (this.type == 'Sendnotificationlist') ? this.filteredResponse.status = `Approved` : '';
      (this.type == 'LeadUsers') ? this.filteredResponse.isLeadUser = true : '';
      (this.type == 'LeadUsers') ? this.filteredResponse._id = this.tableSetupData.id : '';
      if (this.type == 'TemplateApproval') {
        this.filteredResponse.status = this.tableSetupData.status;
        this.filteredResponse.filter = this.tableSetupData.filter;
      }
      if (colName == 'status') {
        this.filterlist = ['Active', 'Inactive'];
      } else if (colName == 'role') {
        this.CallAPIgetFilterList(this.callAPIConstants.RoleGetColumnData, this.filteredResponse);
      }
      else if (colName == 'createdAt') {
        this.modifyFilterArr(colName, [event]);
      }
      else {
        this.CallAPIgetFilterList(this.getFilterlist, this.filteredResponse);

        // if (colName == 'qualification' || colName == 'qualificationName') {
        //   this.filter.forEach(data => {
        //     for (let key in data) {
        //       if (key == 'categoryType' && (this.filteredResponse.column == 'qualification' || this.filteredResponse.column == 'qualificationName')) {
        //         this.filteredResponse['filter'] = [{ categoryType: data[key] }];
        //         this.CallAPIgetFilterList(this.getFilterlist, this.filteredResponse);
        //       }
        //     }
        //   }
        //   )
        // }
        // else if (colName == 'categoryType') {
        //   this.filterlist = [];
        //   this.getCategories();
        // }
        // else {
        //   if (this.type !== 'TemplateApproval') {
        //     (this.filteredResponse['filter']) ? delete this.filteredResponse['filter'] : ''
        //   }
        //   if (colName !== 'categoryType') {

        //     this.CallAPIgetFilterList(this.getFilterlist, this.filteredResponse);

        //   }

        // }
        // }
        // else {
        //   this.notFoundText = "Type at least " + '2' + " characters."
        // }
      }
    }
  }
  getCategories() {
    this.commonService.callApi('admin/getCategory', '', 'post', false, false).then((success) => {
      if (success.status == 1) {
        this.filterlist = [];
        success.data.categoryData.forEach(element => {
          this.filterlist.push(element.categoryType);
        });;
      }

    });
  }
  /***************************************************************
@Purpose :To Get Filtered Values based on Columns.
  /***************************************************************/
  CallAPIgetFilterList(method, data) {
    this.commonService.callApi(method, data, 'post', false, false).then((success) => {
      if (success.status == 1) {
        if (success.data.listing.length) {
          this.filterlist = success.data.listing;
          if (data.column === "csvCentreRole") {
            this.filterlist = [];

            success.data.listing.forEach((ele) => {
              if (ele === 'Preparation_Centre') {
                this.filterlist.push('Preparation Centre');
              } else if (ele === 'Authorised_Centre') {
                this.filterlist.push('Authorised Centre');
              }
              else if (ele == 'Admin') {
                this.filterlist.push('Admin');
              }
              // this.filterlist = [...this.filterlist];
            });
            // this.filterlist.forEach(ele => {
            //   this.filterlist = [];
            //   this.filterlist.push(ele == 'Authorised_Centre' ? ele = 'Authorised centre' : ele = 'Preparation Centre');
            // })
          }
        }
        else {
          this.notFoundText = 'No Data Found.'
        }
      }
    });
  }
  /***************************************************************
  @Purpose :To Get Values of filters.
  /***************************************************************/
  selectFilter(fieldName, event) {
    if (fieldName == 'status') {
      if (event == 'Active') {
        this.modifyFilterArr(fieldName, [true]);
      } else if (event == 'Inactive') {
        this.modifyFilterArr(fieldName, [false]);
      } else {
        this.modifyFilterArr(fieldName, [true, false]);
      }
    } else {
      this.modifyFilterArr(fieldName, event);
    }
  }

  /***************************************************************
@Purpose :To Apply Filter.
  /***************************************************************/
  applyFilter() {

    this.APIparameters.filter = JSON.parse(JSON.stringify(this.filter));
    this.APIparameters.page = 1;
    if (this.filter.length) {
      this.isFilterApplied = false;

      this.APIparameters.filter.forEach((data, index) => {
        for (const key in data) {
          if (key === 'authCentreName') {
            data['authCentreData.centreName'] = [];
            data['authCentreData.centreName'] = data[key];
            delete data[key];
          }
          else if (key === 'prepCentreName' && this.type != 'Student/ParentList') {
            data['PrepCentreData.centreName'] = [];
            data['PrepCentreData.centreName'] = data[key];
            delete data[key];
          }
          else if (key === 'csvCentreRole' && this.type === 'LeadsList') {
            let filterList = [];
            data[key].forEach(ele => {
              (ele == 'Authorised Centre' ? ele = 'Authorised_Centre' : ele == 'Preparation Centre' ? ele = 'Preparation_Centre' : ele = 'Admin')
              filterList.push(ele);
            })
            data['csvCentreRole'] = filterList;
          }
        }
      });
      this.callApiToGetData();

    }
    else {
      setTimeout(() => {
        this.popToast('error', 'Please Choose Atleast One Filter');
      }, 200);
    }

    if (this.dataOfSaveFilter) {
      this.dataOfSaveFilter.map((x => {
        if (x.isFilterApplied) {
          delete x.isFilterApplied;
        }
      }))
    }
  }
  /***************************************************************
@Purpose :To Reset Filters.
  /***************************************************************/
  resetFilter() {
    this.isFilterApplied = true;
    // debugger
    this.tableConfig.cols.forEach((element) => {
      element.value = [];
    });
    this.filter = [];
    this.APIparameters.filter = [];
    if (this.APIparameters.searchText) {
      delete this.APIparameters.searchText
    }
    if (this.dataOfSaveFilter) {
      this.dataOfSaveFilter.map((x => {
        if (x.isFilterApplied) {
          delete x.isFilterApplied;
        }
      }))
    }
    this.tableConfig.cols.forEach(element1 => {
      if (element1.colName == 'Qualification') {
        element1['disabled'] = true;

      }
    });
    this.callApiToGetData();
  }

  /***************************************************************/

  /***************************************************************
  @Purpose : SAVE and DELETE FILTER.
  /***************************************************************/
  sendEmail(i) {
    this.commonService.callApi(`/admin/verifyStudentFromAdmin/${i._id}`, '', 'get').then(res => {
      if (res.status === 1) {
        this.popToast('success', res.message);
        this.callApiToGetData();
      }
      else {
        this.popToast('error', res.message);
      }
    })
  }
  openSaveFilterModal(saveFilters: TemplateRef<any>) {
    this.BsModalRef = this.modalService.show(saveFilters);
  }
  submitSaveFilter(saveFilterForm, filterToSave) {
    this.tableConfig.cols.forEach((element) => {
      element.value = [];
    });
    this.submitted = true;
    if (saveFilterForm.valid && this.filter) {
      const filter: any = {
        filterName: filterToSave.filterName,
      };
      if (this.type == 'Sendnotificationlist') {
        filter.status = 'Approved';
      } else if (this.type == 'TemplateApproval') {
        filter.status = this.tableSetupData.status;
      }
      else if (this.type == 'LeadUsers') {
        filter.isLeadUser = true;
      }
      else if (this.type == 'LeadsList') {
        filter.isLeadUser = false;
      }
      filter.filter = JSON.parse(JSON.stringify(this.filter));
      this.filter = [];
      this.commonService.callApi(this.saveFilter, filter, 'post', false, false).then((success) => {
        if (success.status == 1) {
          this.popToast('success', 'Filter is saved Successfully');
          this.CallAPIgetSavedFilter();
        }
      });
      this.submitted = false;
      this.BsModalRef.hide();
    } else {
      // this.popToast('error', 'Enter Values to Save');
    }
    saveFilterForm.reset();
  }
  /***************************************************************
@Purpose :To CallApi for SavedFilters.
  /***************************************************************/
  CallAPIgetSavedFilter() {
    if (this.getFilter) {
      let apiUrl: any;
      let tempVal: any;
      (this.type == 'LeadsList') ? tempVal = false : tempVal = true;
      ((this.type == 'TemplateApproval' && this.tableSetupData.status == 'Pending') ?
        apiUrl = `${this.getFilter}/pending` : (
          (this.tableSetupData.status == 'Approved' && this.type == 'TemplateApproval') ? apiUrl = `${this.getFilter}/approve` :
            (this.type == 'EmailList') ? apiUrl = `${this.getFilter}/admin` : (this.type == 'campaignAnalytics') ?
              apiUrl = `${this.getFilter}/isAnalytic` : (this.type == 'Sendnotificationlist') ?
                apiUrl = `${this.getFilter}/Approved` : (this.type == 'campaignAnalyticsList') ?
                  apiUrl = `${this.getFilter}/isAdminAnalytic` : (this.type == 'Communicationlist') ?
                    apiUrl = `${this.getFilter}/isAdminCommunication` :
                    (this.type === 'LeadUsers') ? apiUrl = `${this.getFilter}/${tempVal}` : (this.type === 'LeadsList') ? apiUrl = `${this.getFilter}/${tempVal}` : (this.type === 'adminUserlist') ? apiUrl = `${this.getFilter}/undefined` : apiUrl = this.getFilter));
      this.commonService.callApi(apiUrl, '', 'get', false, false).then((success) => {
        if (success.status == 1) {
          this.dataOfSaveFilter = success.data;
        }
      });
    }
  }

  /***************************************************************
@Purpose :To Show SavedFilters.
  /***************************************************************/
  showSaveFilter(i) {
    this.dataOfSaveFilter.forEach((x => {
      if (x == i) {
        x.isFilterApplied = true;
      }
      else {
        x.isFilterApplied = false;
      }
    }))
    this.isFilterApplied = false;
    this.APIparameters.filter = JSON.parse(JSON.stringify(i.filter));
    this.APIparameters.filter.forEach((data, index) => {
      for (const key in data) {
        if (key === 'authCentreName') {
          data['authCentreData.centreName'] = [];
          data['authCentreData.centreName'] = data[key];
          delete data[key];
        } else if (key === 'categoryType') {
          data['categoryData.categoryType'] = [];
          data['categoryData.categoryType'] = data[key];
          delete data[key];
        }
        else if (key === 'qualificationName' && (this.type == 'Student/ParentList')) {
          data['qualificationName'] = [];
          data['qualificationName'] = data[key];
          delete data[key];
        }
        else if (key === 'categoryType') {
          data['categoryData.categoryType'] = [];
          data['categoryData.categoryType'] = data[key];
          delete data[key];
        } else if (key === 'qualificationName' && (this.type == 'EmailList'
          || this.type === 'TemplateApproval')) {
          data['qualificationData.qualificationName'] = [];
          data['qualificationData.qualificationName'] = data[key];
          delete data[key];
        }
      }
    });
    this.callApiToGetData();
  }
  /***************************************************************
@Purpose :To Call DeleteSaveFilters.
/***************************************************************/
  CallAPIdeleteSaveFilter(i, index) {
    let apiUrl: any;
    (this.type == 'TemplateApproval' && this.tableSetupData.status == 'Pending') ? apiUrl = `${this.deleteFilter}${i._id}/pending` : (
      (this.tableSetupData.status == 'Approved' && this.type == 'TemplateApproval') ? apiUrl = `${this.deleteFilter}${i._id}/approve` :
        (this.type == 'EmailList') ? apiUrl = `${this.deleteFilter}${i._id}/admin` : (this.type == 'campaignAnalytics') ? apiUrl = `${this.deleteFilter}${i._id}/isAnalytic` : (this.type == 'Sendnotificationlist') ? apiUrl = `${this.deleteFilter}${i._id}/Approved` : (this.type == 'campaignAnalyticsList') ? apiUrl = `${this.deleteFilter}${i._id}/isAdminAnalytic` : (this.type == 'Communicationlist') ? apiUrl = `${this.deleteFilter}${i._id}/isAdminCommunication` : (this.type == 'LeadUsers') ? apiUrl = `${this.deleteFilter}${i._id}/${true}` : (this.type == 'LeadsList') ? apiUrl = `${this.deleteFilter}${i._id}/${false}` : apiUrl = this.deleteFilter + i._id);
    this.commonService.callApi(apiUrl, '', 'delete', false, false).then((success) => {
      if (success.status === 1) {
        this.isFilterApplied = true;
        this.popToast('success', success.message);
        this.dataOfSaveFilter.splice(index, 1);
        this.APIparameters.filter = [];
        this.callApiToGetData();
      }
    });
    this.CallAPIgetSavedFilter();
  }
  /***************************************************************/

  /***************************************************************
@Purpose : Common Function.
  /***************************************************************/
  deselectMultipleUser() {
    this.tableData.data.forEach((element) => {
      element.checkbox = false;
      const x = this.selectedUserList.findIndex((o) => o === element._id);
      if (x > -1) {
        this.selectedUserList.splice(x, 1);
      }
    });
  }
  /***************************************************************
@Purpose : To Modify FilterArray.
  /***************************************************************/
  modifyFilterArr(fieldName, event) {
    if ((fieldName == 'categoryType' || fieldName == 'categoryName') && event.length) {
      this.tableConfig.cols.forEach(element1 => {
        if (element1.colName == 'Qualification') {
          element1['value'] = [];
        }
      });
    }
    const index = this.filter.findIndex((o) => Object.keys(o).includes(fieldName));
    if (index > -1) { this.filter[index][fieldName] = event; } else {
      this.filter.push({ [fieldName]: event });
      this.filter.forEach(element => {
        for (const elem in element) {
          if (elem == 'categoryType') {
            if (element.categoryType && element.categoryType.length) {
              this.tableConfig.cols.forEach(element1 => {
                if (element1.colName == 'Qualification') {
                  element1['disabled'] = false;
                  this.entityTypeSelected = true;
                }
              });
            }
            else {
              this.tableConfig.cols.forEach(element1 => {
                if (element1.colName == 'Qualification') {
                  element1['disabled'] = true;
                  // delete element1['qualification'];
                }
              });
            }

          }
        }
      })
    }
    if (!event.length) {
      this.tableConfig.cols.forEach(element1 => {
        if (element1.colName == 'Qualification') {
          element1['value'] = [];
        }
      });
      this.filter.splice(index, 1)
    }

  }

  /***************************************************************/

  /***************************************************************
@Purpose : To change the Centre Status  //
/***************************************************************/
  changeCentreStatus(data) {
    this.swal({
      text: 'Do you want to change the status of this ' +
        (this.type == 'AcList' ? 'Authorised Centre' : this.type == 'PcList' ? 'Preparation Centre'
          : this.type == 'Student/ParentList' ? 'Student/Parent' : this.type == 'EmailList' ? 'Email Template' :
            this.type == 'qualificationList' ? 'Qualification' : 'N/A')
        + ' from ' + (data.status == false ? 'Active' : 'Inactive') +
        ' to ' + (data.status == true ? 'Active' : 'Inactive'),
      type: 'question',
      showCancelButton: true,
      confirmButtonClass: 'btn btn-primary',
      cancelButtonClass: 'btn btn-info',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
    }).then((result) => {
      let apiUrl: any;
      let apiData: any = {};
      if (this.type == 'AcList' || this.type == 'PcList' || this.type == 'Student/ParentList') {
        apiUrl = 'admin/changeStatus';
        apiData = {
          userType: (this.type == 'AcList' ? 'authCentre' : this.type == 'PcList' ? 'prepCentre' : this.type == 'Student/ParentList' ? 'Student/Parent' : 'N/A'),
          status: data.status,
          userIds: [data._id],
        };
      } else if (this.type == 'EmailList') {
        apiUrl = 'notification/addUpdateTemplate';
        apiData = {};
        apiData.status = data.status;
        apiData.id = data._id;
        apiData.createdBy = this.getToken('adminUserId');
      } else if (this.type == 'qualificationList') {
        apiUrl = 'admin/qualificationStatus';
        apiData = {};
        apiData.status = data.status;
        apiData._id = data._id;
      }
      if (result.value) {
        this.commonService.callApi(apiUrl, apiData, 'post').then((success) => {
          if (success.status == 1) {
            this.popToast('success', success.message);
            this.callApiToGetData();
          } else {
            this.popToast('error', success.message);
          }
        });

      } else {
        // this.callApiToGetData();
        data.status = !data.status;
      }
    });
  }

  /****************************************************************************
   @Purpose : To get search list.
   /****************************************************************************/
  searchLisitng(value): Observable<any> {
    if (value.length >= 3) {
      this.APIparameters['searchText'] = value;
      this.APIparameters.page = 1;
      // if (this.APIparameters.filter) {
      //   delete this.APIparameters.filter;
      // }
      this.callApiToGetData();
      return of([]);
    } else {
      delete this.APIparameters.searchText;
      this.callApiToGetData();
      return of([]);
    }
  }
  /****************************************************************************
   @Purpose : To Navigate the page.
   /****************************************************************************/
  routerNavigation(type, i) {

    if (!this.sample.length || !$('body').hasClass('modal-open')) {
      if (type == 'PcList') {
        this.router.navigate([this.URLConstants.PREPARATIONDETAIL, i._id]);
      }
      else if (type === 'PcDetail') {
        this.router.navigate([this.URLConstants.STUDENTQUALIFICATIONLIST, i._id]);
      } else if (type === 'CsvList') {

        this.router.navigate([this.URLConstants.CSVSTUDENTLIST, i._id, i.csvFileName, i.categoryName]);
      } else if (this.type === 'CsvDetail' || this.type == 'paidRegisteredUserListing') {
        this.router.navigate([this.URLConstants.CSVSTUDENTDETAILS, i._id, i.categoryType]);
      } else if (type === 'LeadsList') {

        this.router.navigate([this.URLConstants.LeadsUsersList, i._id, i.csvFileName, i.csvFileName]);
      } else if (this.type === 'CsvDetail' || this.type == 'paidRegisteredUserListing') {
        this.router.navigate([this.URLConstants.CSVSTUDENTDETAILS, i._id, i.categoryType]);
      }
      else if (type === 'Student/ParentList') {
        this.router.navigate([this.URLConstants.STUDENTDETAILS, i._id]);
      }
    }
    else {
    }
  }
  /***************************************************************
@Purpose : To Get Dynamic FilterValues.
  /***************************************************************/
  filterValue(data) {
    if (this.type == 'CsvList' || this.type == 'EmailList') {
      this.APIparameters.filter = data;
      this.callApiToGetData();
    }
  }
  /***************************************************************
@Purpose : ColSettiings Validation to select atleasetOne.
/***************************************************************/
  changeCheckBoxValue() {
    // this.rerenderDataTable();
    let length = this.tableConfig.cols.length;
    let actionExists = false;
    let p = 0;
    this.tableConfig.cols.forEach((k) => {
      if (k.type == 'action' || k.type == 'statusActions') {
        actionExists = true;
      }
      if (k.isVisible) { p++; }
    });
    if ((p === 1 && !actionExists) || (p == 2 && actionExists)) {
      this.tableConfig.cols.forEach((k) => {
        if (k.isVisible) {
          k.disable = true;
        }
      });
    } else {
      this.tableConfig.cols.forEach((k) => {
        k.disable = false;
      });
    }
  }
  typeaheadOnBlur(event: any): void {
    this.setFocus = false;
  }
  // bindu development
  /***************************************************************/
  // SELECT ALL USERS IN TABLE //
  /***************************************************************/
  selectAllUser(i) {
    if (i.checkbox === true) {
      this.tableData.data.forEach((element: any) => {
        element.checkbox = true;
        element.isSelected = true;
        if (this.type !== 'csvLeadsList1') {
          if (this.selectedUserList.length) {
            const x = this.selectedUserList.findIndex((o) => o._id === element._id);
            if (x === -1) {
              this.selectedUserList.push(element);
            }
          } else {
            this.selectedUserList.push(element);
          }
        } else if (this.type === 'csvLeadsList1') {
          const x = this.selectedFileList.findIndex((o) => o === element._id);
          if (x === -1) {
            this.selectedFileList.push(element._id);
          }
        } else {
          this.selectedFileList.push(element._id);

        }

      });
    } else {
      this.tableData.data.forEach((element: any) => {
        element.checkbox = false;
        element.isSelected = false;
        if (this.type == 'csvLeadsList') {
          const x = this.selectedUserList.findIndex((o: any) => o._id === element._id);
          if (x > -1) {
            this.selectedUserList.splice(x, 1);
          }
        } else {
          const x = this.selectedFileList.findIndex((o: any) => o === element._id);
          if (x > -1) {
            this.selectedFileList.splice(x, 1);
          }
        }
      });
    }
  }
  /***************************************************************/
  UnselectUsers() {
    this.tableConfig.cols.map((element: any) => {
      if (element.type === 'multipleSelection') {
        element.checkbox = false;
      }
    });
    // tslint:disable-next-line: no-identical-functions
    this.tableData.data.forEach((element: any) => {
      element.checkbox = false;
      element.isSelected = false;
      const x = this.selectedUserList.findIndex((o: any) => o._id === element._id);
      if (x > -1) {
        this.selectedUserList.splice(x, 1);
      }
    });
  }
  /***************************************************************/
  // For sending the notification
  /***************************************************************/
  public seletedLeadsList: any = [];
  sendNotification(data, type) {
    const obj: any = {};
    if (type === 'LeadUsers' || type === 'LeadsList') {
      if (type === 'LeadsList') {
        this.seletedLeadsList = [];
        this.selectedUserList.map((obj) => {
          let data1 = {
            type: obj.csvCentreRole === 'Authorised Centre' ? 'ac' : 'pc',
            leadfileId: obj._id
          }
          this.seletedLeadsList.push(data1);
        });
        obj['leadData'] = this.seletedLeadsList;
      } else {
        obj.id = data._id;
        obj.type = 'admin';
      }
      this.commonService.callApi(this.callAPIConstants.LeadSendMail, obj, 'post', false, false).then((response: any) => {
        if (response.status === 1) {
          this.popToast('success', response.message);
        } else {
          this.popToast('error', response.message);
        }
      });
    } else if (type === 'csvLeadsList') {
      let ids = this.selectedUserList.map((obj) => {
        return obj._id
      });
      this.setToken('selectedUserList', JSON.stringify(ids));
      let id = this.selectedUserList[0].csvQualification;
      // this.receivedData.emit(data);
      this.router.navigate([this.URLConstants.SENDNOTIFICATION, id]);
      // this.BsModalRef = this.modalService.show(SelectCsvLeadsComponent,
      //   { ignoreBackdropClick: true, keyboard: false, class: 'modal-lg modal-dialog-centered', initialState: { data: this.selectedUserList[0] } });

    }

    else {
      // const dataSending: any = {
      //   notificationData: [],
      // };
      // this.selectedUserList.forEach((x: any) => {
      //   dataSending.notificationData.push({
      //     templateId: x._id,
      //     qualificationId: x.qualificationId,
      //     type: x.notificationType,
      //   });
      // });
      // tslint:disable-next-line: max-line-length
      // this.commonService.callApi(this.callAPIConstants.massEmailSMSNotificationSend, dataSending, 'post', false, false).then((success: any) => {
      //   if (success.status === 1) {
      //     this.popToast('success', success.message);
      //     // tslint:disable-next-line: no-identical-functions
      //     this.tableData.data.forEach((element: any) => {
      //       element.checkbox = false;
      //       element.isSelected = false;
      //       const x = this.selectedUserList.findIndex((o: any) => o._id === element._id);
      //       if (x > -1) {
      //         this.selectedUserList.splice(x, 1);
      //       }
      //     });
      //   } else {
      //     this.popToast('error', success.message);
      //   }
      // });
      this.BsModalRef = this.modalService.show(SendStudentNotificationsComponent,
        { ignoreBackdropClick: true, keyboard: false, class: 'modal-md modal-dialog-centered', initialState: { data: data } });
    }

  }



  /***************************************************************/


  public selected = -1;
  public seletedCSVTemplate: any = [];
  public seletedUserIds: any;
  // selectSingleUser(data) {
  //   this.seletedCSVTemplate = [];
  //   this.seletedCSVTemplate.push(data);
  //   this.seletedUserIds = JSON.parse(this.getToken('selectedUserList'));
  // }
  selectSingleUser(data, event) {
    if (event.target.checked) {
      this.seletedCSVTemplate = [];
      this.seletedCSVTemplate.push(data);
      this.seletedUserIds = JSON.parse(this.getToken('selectedUserList'));
    } else {
      if (this.seletedCSVTemplate.length) {
        this.seletedCSVTemplate.splice(0, 1);
      }
    }
  }
  sendSeletedLeadNotification() {
    let finalData = {
      ids: this.seletedUserIds ? this.seletedUserIds : this.selectedFileList,
      templateId: this.type !== 'csvLeadsList1' ? this.seletedCSVTemplate[0]._id : this.params.statusParams,
      role: 'admin'
    }
    this.commonService.callApi('csvleadfile/sendMultipleMail', finalData, 'post', false, false).then((success: any) => {
      if (success.status === 1) {
        if (this.type == 'csvLeadsList') {
          this.router.navigate([this.URLConstants.CsvLeads]);
        } else {
          this.BsModalRef.hide();
        }
        this.removeToken('selectedUserList');
        this.popToast('success', success.message);
      } else {
        this.popToast('error', success.message);
      }
    });
  }

  /*************************************************************/
  // select users and change status
  /*************************************************************/
  // tslint:disable-next-line: member-ordering
  // public statusFormData = { status: '' };
  // public statusList: any[];

  selectMultipleUser(data) {
    if (data.checkbox) {

      if (this.type !== 'csvLeadsList1') {
        if (this.selectedUserList.length) {
          const x = this.selectedUserList.findIndex((o: any) => o._id === data._id);
          if (x === -1) {
            this.selectedUserList.push(data);
          }
        } else {
          this.selectedUserList.push(data);
        }
      } else if (this.type === 'csvLeadsList1') {
        if (this.selectedFileList.length) {
          const x = this.selectedFileList.findIndex((o: any) => o === data._id);
          if (x === -1) {
            this.selectedFileList.push(data._id);
          }
        } else {
          this.selectedFileList.push(data._id);
        }
      }
    } else {
      this.tableConfig.cols.forEach((element: any) => {
        element.checkbox = false;
      });
      if (this.type !== 'csvLeadsList1') {
        const x = this.selectedUserList.findIndex((o: any) => o._id === data._id);
        if (x > -1) {
          const index = this.tableData.data.findIndex((i: any) => i._id === this.selectedUserList[x]._id);
          this.tableData.data[index].isSelected = false;
          this.selectedUserList.splice(x, 1);
        }
      } else if (this.type === 'csvLeadsList1') {
        const x = this.selectedFileList.findIndex((o: any) => o === data._id);
        if (x > -1) {
          const index = this.tableData.data.findIndex((i: any) => i._id === this.selectedFileList[x]);
          this.tableData.data[index].isSelected = false;
          this.selectedFileList.splice(x, 1);
        }
      }
    }
    if (this.type === 'Sendnotificationlist') {
      data.isSelected = true;
    }
  }
  // bindu dev ends
  /*************************************************************
@purpose :To destroy the events
/*************************************************************/
  ngOnDestroy() {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.studentUpdateBroadCaster.unsubscribe();
  }
}
