export const URLConstants = {

    LOGIN: '/public/login',
    FORGOT_PASS: '/public/forgot-password',
    VERIFICATION: '/public/verification',
    RESET_PASS: '/public/reset-password',
    SET_PASS: '/public/set-password',
    DASHBOARD: '/main/dashboard',
    CHANGE_PASS: '/main/change-password',
    MY_PROFILE: '/main/my-profile',
    ADMINPROFILE: '/main/admin-profile',
    USERS: '/main/users/users-list',
    ADMINUSER_LIST: '/main/admin-users/list',
    ADMINUSER_DETAIL: '/main/admin-users/detail',
    ADDADMINUSER: '/main/admin-users/AddAdminUser',
    HOME: '/main/contents-management/home',
    CMS: '/main/contents-management/cms/list',
    CMS_DETAIL: '/main/contents-management/cms/detail/',
    ADD_NEW_CMS: '/main/contents-management/cms/addNewCms',
    ADMIN_EMAIL: '/main/email-management/admin-email',
    EMAIL_TEMPLATE_list: '/main/email-management/email-template/list',
    EMAIL_TEMPLATE_detail: '/main/email-management/email-template/detail/',
    SMTP_SETTING: '/main/email-management/smtp-setting',
    STATE: '/main/master-management/state',
    COUNTRY: '/main/master-management/country',
    CITY: '/main/master-management/city',
    GLOBAL_SETTINGS: '/main/settings/global-settings',
    PAYMENT_GATEWAY: '/main/settings/payment-gateway',
    MANAGE_ROLES: '/main/manage-roles/list',
    ADDNEWROLE: '/main/manage-roles/addNewRole',
    MANAGE_ROLES_DETAIL: '/main/manage-roles/detail/',
    NOTIFICATION: '/main/settings/notification',
    AUTH: '/main/authorised',
    AUTHDETAIL: '/main/authorised/authorised-detail',
    PREPARATION: '/main/preparation',
    PREPARATIONDETAIL: '/main/preparation/preparation-detail',
    STUDENTQUALIFICATIONLIST: '/main/preparation/students-list',
    QUALIFICATIONLIST: '/main/qualification/qualification-list',
    QUALIFICATIONDETAIL: '/main/qualification/qualification-detail',
    STUDENT: '/main/student',
    STUDENTDETAILS: '/main/student/student-details',
    EMAILLIST: '/main/email/email-list',
    EMAILDETAIL: '/main/email/email-detail',
    EMAILAPPROVALDETAIL: '/main/email/email-approve-detail',
    EMAILAPPROVAL: '/main/email/email-aprroval',
    CSV: '/main/csv/csv-list',
    Leads: '/main/leads/leads-list',
    UploadLeads: '/main/leads/upload-leads',
    CsvLeads: '/main/leads/csv-leads',
    LeadsUsersList: '/main/leads/lead-users-list',
    CSVSTUDENTLIST: '/main/csv/csv-student-list',
    CSVSTUDENTDETAILS: '/main/csv/csv-student-detail',
    SAMPLECSV: '/main/csv/sample-csv-list',
    CAMPAIGNMANAGER: '/main/campaign-manager',
    CAMPAIGNANALYTICS: '/main/campaign-analytics',
    LOCATION: '/main/location',
    REPORT: '/main/report',
    EXAMS: '/main/calendar',
    EXAMSLIST: '/main/calendar/calendar-list',
    EXAMSDTEAILS: '/main/calendar/calendar-details',
    STUDENTEXAMS: '/main/calendar/student-details',
    GLOBALCALENDAR: '/main/calendar/global-calendar',
    COMMUNICATION: '/main/communication',
    SENDING_NOTIFICATIONS: '/main/communication/sending-notifications',
    CAMPAIGN: '/main/campaign',
    CAMPAIGN_ANALYTICS: '/main/campaign/Analytics',
    paidStudents: '/main/paid-students',
    NotPaidStudents: '/main/not-paid-students',
    CSVRegisteredStudents: '/main/csv-registered-students',
    ToBeRegistered: '/main/to-be-registered-students',
    SENDNOTIFICATION: '/main/leads/send-notification'
};
