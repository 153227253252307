import { Injectable, Injector, APP_ID, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, CanLoad, CanDeactivate } from '@angular/router';

// Component
import { BaseComponent } from './commonComponent';

/****************************************************************************
@PURPOSE      : Dont allow public pages to get accessed. (After Login)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
@Injectable()
export class CanLoginActivate extends BaseComponent implements CanActivate {
  constructor(inj: Injector) { super(inj) }
  canActivate() {
    if (!this.getToken("accessToken")) {
      return true;
    }
    this.router.navigate([this.URLConstants.DASHBOARD]);
    return false
  }
}
/****************************************************************************/

/****************************************************************************
@PURPOSE      : Dont allow authorized pages to get accessed.  (Before Login)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
@Injectable()
export class CanAuthActivate extends BaseComponent implements CanActivate {
  constructor(inj: Injector) {
    super(inj)
  }
  canActivate() {
    return this.noAccessToAuthorizedPages();
  }
}
/****************************************************************************/

/****************************************************************************
@PURPOSE      : Allow children of main.module to get access after login
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
@Injectable()
export class CanAuthActivateChild extends BaseComponent implements CanActivateChild {
  constructor(inj: Injector) {
    super(inj)
  }
  canActivateChild() {
    return this.noAccessToAuthorizedPages();
  }
}
/****************************************************************************
@PURPOSE      : Dont allow to Create in any Module without permission (create)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
@Injectable()
export class CanCreateActivate extends BaseComponent implements CanActivate {
  public role: any;

  constructor(inj: Injector) {
    super(inj)
  }

  canActivate(route: ActivatedRouteSnapshot) {
    let module:any={};
    if(route.url[0].path ==='AddAdminUser'){
      module='adminUserAccess';
    } else if(route.url[0].path ==='authorised-detail'){
      module='authorisedCentreAccess';
    }else if(this.router.url.includes('main/calendar/calendar-list')){
      module='examAccess';
    } else if(this.router.url.includes('/main/email/email-list')){
      module='manageTemplateAccess';
    }
    this.role = this.getRolePermissions(module);
    return this.CanActivateFalseRouteDashboard(module==='examAccess'?this.role.createExamDate:this.role.create);
  }
}
/****************************************************************************/

/****************************************************************************
@PURPOSE      : Dont allow to Edit in any Module without permission (edit)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
@Injectable()
export class CanEditActivate extends BaseComponent implements CanActivate {
  public role: any ={};

  constructor(inj: Injector) {
    super(inj)
  }

  canActivate(route: ActivatedRouteSnapshot) {
    let module:any={};
    if(this.router.url.includes('/main/admin-users/list')){
      module='adminUserAccess';
    } else if(route.url[0].path ==='authorised-detail'){
      module='authorisedCentreAccess';
    }else if(this.router.url.includes('/main/preparation/preparation-list')){
      module='preparationCentreAccess';
    }else if(this.router.url.includes('/main/calendar/calendar-list')){
      module='examAccess';
    }else if(this.router.url.includes('/main/email/email-list')){
      module='manageTemplateAccess';
    }  else if(this.router.url.includes('main/email/email-aprroval') || this.router.url.includes('/main/dashboard')){
      module='approveTemplateAccess';
    }
    this.role = this.getRolePermissions(module);
    return this.CanActivateFalseRouteDashboard(module==='examAccess'?this.role.editExamDate:module==='approveTemplateAccess'?this.role.update:this.role.edit);
  }
}
/****************************************************************************/

/****************************************************************************/
