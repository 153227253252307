import { Component, OnInit, Injector, Input } from '@angular/core';
import { BaseComponent } from 'src/app/common/commonComponent';
import { NavigationExtras } from '@angular/router';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { Location } from '@angular/common';

@Component({
  selector: 'app-add-edit-email',
  templateUrl: './add-edit-email.component.html',
  styles: []
})
export class AddEditEmailComponent extends BaseComponent implements OnInit {

  public passwordShow: boolean = true;
  public confirmPasswordShow: boolean = true;
  public adminUserId: any;
  public roleList: Array<any> = [];
  public examsArr: Array<any> = [];
  public file: any;
  public apiUrl: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  public sliceSize: any;
  public imageURL = "";
  @Input() subType: any = { permission: 'true' };
  public statusList: Array<any> = [{ name: 'Active', value: true }, { name: 'inactive', value: false }];
  public locationArr: Array<any> = [{ name: 'india', value: 'india' }, { name: 'sriLanka', value: 'sriLanka' }];
  public regionArr: Array<any> = [{ name: 'Bihar', value: 'bihar' }, { name: 'Patna', value: 'patna' }, { name: 'Mumbai', value: 'mumbai' }];
  public cityArr: Array<any> = [{ name: 'Vadodara', value: 'Vadodara' }, { name: 'Surat', value: 'surat' }, { name: 'Maninagar', value: 'maninagar' }];
  public recipientTypeArr: any[] = [{ name: 'Leads' }, { name: 'Registered Candidates' }, { name: 'Candidates To Be Registered' }];
  public authCentreArr: Array<any> = [];
  public emailTypeArr: any[] = [{ name: 'Email' }, { name: 'Sms' }];
  public TypeArr: any[] = [{ name: 'Update' }, { name: 'Promotion' }, { name: 'Others' }];
  public qualificationArr: Array<any> = [];
  public email: any = {};
  public submitted = false;
  public grapesJsData: any = {};
  public type: any = 'emailDetails'
  entityArr: any;
  public storedTemplateName: any;
  constructor(inj: Injector, private location: Location) {
    super(inj)
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.adminUserId = params.id;
        this.getFormDetails();
      }
      else {

      }
    })
  }

  ngOnInit() {
    this.adminUserId ? this.getFormDetails() : this.grapesJsData = { gjsHtml: '', gjsCss: '' };
    // this.getEntityTypes();
    this.getQualifications('id');
  }
  /*************************************************************
@purpose :To GetFormDetails.
/*************************************************************/
  getFormDetails() {
    setTimeout(() => {
      this.commonService.callApi(`notification/getTemplateById/${this.adminUserId}`, '', 'get', false, false).then(success => {
        if (success.status === 1) {
          this.email = success.data[0];
          this.storedTemplateName = success.data[0].templateName;
          if (this.email.notificationType !== 'Sms') {
            this.setToken('gjs-html', success.data[0].templateData);
            this.setToken('gjs-css', success.data[0].templateStyles);
            this.grapesJsData = { gjsHtml: success.data[0].templateData, gjsCss: success.data[0].templateStyles }
          }
          this.getQualifications(this.email.categoryId);

        }
      })
    }, 10);
  }
  /***************************************************************/
  // Add or Update EmailTemplate //
  /***************************************************************/
  addEditEmail(emailForm, email) {
    this.submitted = true;
    if (emailForm.valid && email.notificationType == 'Sms' || emailForm.valid && email.notificationType === 'Email' && this.getToken('gjs-html')) {

      // this.email.emailText = this._editor.runCommand('gjs-get-inlined-html');
      // email.templateData = "<style>" + email.templateStyles + "</style>" + email.templateText;
      if (this.adminUserId) {
        if (this.email.notificationType !== 'Sms') {
          email.templateData = this.getToken('gjs-html');
          email.templateStyles = this.getLocalToken('gjs-css') === null ? this.getLocalToken('gjs-css') : this.getToken('gjs-css');
        }
        // centre.authCentreId = centre._id;
        // this.email.createdBy = this.getToken('adminUserId');
        if (this.subType.emailApproval) {
          this.email.approvalStatus = 'Approved';
        }
        // this.email.templateName = this.adminUserId ? this.email.templateName: this.email.templateName; //because we are not going update template name for old data because we are maintaing three templates.
        this.commonService.callApi(this.callAPIConstants.ADDUPDATETEMPLATE, this.email, 'post', false, false).then(success => {
          if (success.status == 1) {
            this.popToast('success', success.message);
            let routerUrl: any;
            if (this.subType) {
              (!this.subType.emailApproval) ? routerUrl = this.URLConstants.EMAILLIST :
                routerUrl = this.URLConstants.EMAILAPPROVAL;
              this.router.navigate([routerUrl]);
            }
          } else {
            this.popToast('error', success.message);
          }
        })
      } else {
        // admin.role = admin.role._id;
        if (this.email.notificationType !== 'Sms') {
          email.templateData = this.getToken('gjs-html');
          email.templateStyles = this.getLocalToken('gjs-css') === null ? this.getLocalToken('gjs-css') : this.getToken('gjs-css');;
        }
        email.createdBy = this.getToken('adminUserId');
        this.commonService.callApi(this.callAPIConstants.ADDUPDATETEMPLATE, email, 'post', false, false).then(success => {
          if (success.status == 1) {
            this.popToast("success", success.message);
            // adminForm.reset();
            // this.dataTypes.emit({title:'Manage Authorisation centre',authorizedcentreData:'Authorised centre'})
            this.router.navigate([this.URLConstants.EMAILLIST])
            this.submitted = false;
          } else {
            this.popToast("error", success.message);
          }
        })
      }
    }

  }
  addEditSaveEmail(emailForm, email) {
    this.submitted = true;
    if (emailForm.valid && email.notificationType == 'Sms' || emailForm.valid && email.notificationType === 'Email' && this.getToken('gjs-html')) {

      // this.email.emailText = this._editor.runCommand('gjs-get-inlined-html');
      // email.templateData = "<style>" + email.templateStyles + "</style>" + email.templateText;
      if (this.adminUserId) {
        if (this.email.notificationType !== 'Sms') {
          email.templateData = this.getToken('gjs-html');
          email.templateStyles = this.getLocalToken('gjs-css') === null ? this.getLocalToken('gjs-css') : this.getToken('gjs-css');;
        }

        // centre.authCentreId = centre._id;
        // this.email.createdBy = this.getToken('adminUserId');
        if (this.subType.emailApproval) {
          this.email.approvalStatus = 'Approved';
        }
        delete this.email._id;
        // this.email.templateName = this.adminUserId ? this.email.templateName: this.email.templateName; //because we are not going update template name for old data because we are maintaing three templates.
        this.commonService.callApi(this.callAPIConstants.ADDUPDATETEMPLATE, this.email, 'post', false, false).then(success => {
          if (success.status == 1) {
            this.popToast('success', success.message);
            let routerUrl: any;
            if (this.subType) {
              (!this.subType.emailApproval) ? routerUrl = this.URLConstants.EMAILLIST :
                routerUrl = this.URLConstants.EMAILAPPROVAL;
              this.router.navigate([routerUrl]);
            }
          } else {
            this.popToast('error', success.message);
          }
        })
      } else {
        // admin.role = admin.role._id;
        if (this.email.notificationType !== 'Sms') {
          email.templateData = this.getToken('gjs-html');
          email.templateStyles = this.getLocalToken('gjs-css') === null ? this.getLocalToken('gjs-css') : this.getToken('gjs-css');;
        }
        email.createdBy = this.getToken('adminUserId');
        this.commonService.callApi(this.callAPIConstants.ADDUPDATETEMPLATE, email, 'post', false, false).then(success => {
          if (success.status == 1) {
            this.popToast("success", success.message);
            // adminForm.reset();
            // this.dataTypes.emit({title:'Manage Authorisation centre',authorizedcentreData:'Authorised centre'})
            this.router.navigate([this.URLConstants.EMAILLIST])
            this.submitted = false;
          } else {
            this.popToast("error", success.message);
          }
        })
      }
    }
  }
  /***************************************************************/


  /************************************************************** 
  @Purpose :To go back to previous page
  //************************************************************** */
  goBack() {
    this.location.back();
  }
  /*************************************************************
@purpose :To GetQualifications.
/*************************************************************/
  getQualifications(id, change?) {
    this.commonService.callApi('admin/getQualification', '', 'post').then(success => {
      if (success.status == 1) {
        if (change && this.email.qualification) {
          delete this.email.qualification;
        }
        this.qualificationArr = success.data;
        this.qualificationArr.forEach(x => {
          if (x._id) {
            x.qualificationId = x._id;
          }
        })
      }
      else {
        this.popToast('error', success.msg);
      }
    })
  }
  getEntityTypes() {
    this.commonService.callApi('admin/getCategory', '', 'post', false, false).then(success => {
      if (success.status == 1) {
        this.entityArr = success.data.categoryData;
        // this.examsArr = success.data.qualificationLists;
      }
      else {
        this.popToast('error', success.msg)
      }
    })
  }
  /*************************************************************
@purpose : To destroy grapesjs token .
/*************************************************************/

  ngOnDestroy() {
    this.removeLocalToken('gjs-html');
    this.removeLocalToken('gjs-css');
    this.removeLocalToken('gjs-components');
    this.removeLocalToken('gjs-styles');
    this.removeLocalToken('gjs-assets');
    this.removeLocalToken('gjs-get-inlined-html');
    this.removeToken('gjs-get-inlined-html');
    this.removeToken('gjs-html');
    this.removeToken('gjs-css');

  }

  templateTypeChanged(notificationType) {
    // if (this.email.templateData) {
    delete this.email.templateData
    this.grapesJsData = { gjsHtml: '', gjsCss: '' };
    this.removeLocalToken('gjs-html');
    this.removeLocalToken('gjs-css');
    this.removeLocalToken('gjs-get-inlined-html');
    this.removeToken('gjs-html');
    this.removeToken('gjs-css');
    // }
  }
}
