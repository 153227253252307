import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// common
import { CanLoginActivate, CanAuthActivate } from './common/auth.gaurd';


const routes: Routes = [
  {
    path: 'public',
    canActivate: [CanLoginActivate],
    loadChildren: './public/public.module#PublicModule'
  },
  {
    path: 'main',
    canActivate: [CanAuthActivate],
    loadChildren: './main/main.module#MainModule',
  },
  {
    path: '',
    redirectTo: 'public',
    pathMatch: 'full'
  },
  { path: "**", redirectTo: 'public', pathMatch: "full" }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const AppRoutingComponents = [
]