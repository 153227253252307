import { Component, OnInit, Injector, Input, TemplateRef } from '@angular/core';
import { BaseComponent } from '../../common/commonComponent';
import { ITableSetupData } from './../../common/interfaces';
import { yearsPerPage } from '@angular/material/datepicker/typings/multi-year-view';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styles: []
})
export class DataTableComponent extends BaseComponent implements OnInit {
  public initialPagination: any;
  public itemsPerPage = 10;
  public getMethodName: any;
  submitted: boolean = false;
  public total: any;
  public type: any;
  @Input() tableSetupData: ITableSetupData;
  @Input('data') data;
  @Input() CSVLeadData;


  public tempData: any = [];
  public tableConfig: any;
  // public tableData : any;
  // public data : any;
  tableData = {
    cols: ['id', 'author', 'title'],
    data: this.tempData,
    rowSelected: 0,
    value: false,
    type: ''
  };
  selectedUserList: any =[];
  selectedFileList: any=[];
  seletedUserIds: any=[];
  seletedCSVTemplate: any;
  params: any;





  constructor(inj: Injector) {
    super(inj)
    this.initialPagination = { page: 1, pagesize: 10 };
  }

  ngOnInit() {
    this.tableConfig = this.tableSetupData;
    this.type = this.tableConfig.type;
    this.params = this.tableConfig.params;
  }
  ngAfterViewInit() {
    this.callApiToGetData()
  }
  public userId: any;

  callApiToGetData() {

    if (this.type) {
      if (this.type == 'userlist') {
        this.getMethodName = this.callAPIConstants.UserList;
      } else if (this.type == 'cmslist') {
        this.getMethodName = 'admin/getCms'
      }
      else if (this.type == 'scorelist') {
        this.getMethodName = 'admin/getAdminScoreListing'
      }
      else if (this.type == 'csvLeadsList' || this.type==='csvLeadsList1') {
        this.getMethodName = this.callAPIConstants.CSVLeadUsers;
        this.initialPagination.categoryType = this.CSVLeadData.categoryType;
        this.initialPagination.qualification = this.CSVLeadData.qualification;
      }

      this.commonService.callApi(this.getMethodName, this.initialPagination, 'post', false, false).then(success => {

        if (success.status == 1) {
          this.tableData.data = success.data.listing;
          this.total = success.total;
        }
      })
    }

  }

  public sortDirection: any;
  public sortField = {};
  onSorted(event, type) {
    this.initialPagination.sort = {};
    if (event.sortDirection === "desc") {
      this.sortDirection = -1;
    } else {
      this.sortDirection = 1;
    }
    this.initialPagination.sort[event.sortColumn.colFieldname] = this.sortDirection;
    // this.callApiToGetData()

  }
  // *************************************************************//

  /***************************************************************/
  // Pagination for user listing //
  /***************************************************************/
  public currentPage = 1;
  public showBoundaryLinks = false;
  public rangeList = [2, 5, 10, 25, 100];
  //public : any;

  pageChanged(e) {

    this.initialPagination.page = e.page;
    this.itemsPerPage = e.itemsPerPage;
    this.callApiToGetData();
    this.UnselectUsers(); //write the method name here to get new data on page change from backend
  }
  rangeChanged(e) {

    this.initialPagination.pagesize = e;
    this.initialPagination.page = 1;
    this.callApiToGetData() 
    this.UnselectUsers(); //write the method name here to get new data on page change from backend
    //write the method name here to get new data on range change from backend
  }
  /***************************************************************/


  /*************************************************************/
  //User Status
  /*************************************************************/
  public message: any;
  userStatus(k, rowData) {
    this.message = rowData.isBlocked ? 'Are you sure you want to block this user?' : 'Are you sure you want to unblock this user?';
    let data = {
      userId: rowData._id,
      status: rowData.isBlocked ? 'no' : 'yes'
    }
    this.swal({
      text: this.message,
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-outline-primary",
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes",
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
      buttonsStyling: false
      // admin​/updateUserBlockStatus
    }).then(result => {
      if (result.value) {
        this.commonService
          .callApi(
            'admin/updateUserBlockStatus', data, "post", false, false)
          .then(success => {
            if (success.settings.status == 1) {
              this.popToast("success", success.settings.message);
              this.callApiToGetData()
            } else {
              this.popToast("error", success.settings.message);
            }
          });
      }
    });
  }
  premiumAmount(amount) {
    this.submitted = true;
  }

  performAction(k, i) {
    // view action
    if (k.id === 2) {
    }
  }
/***************************************************************/
  // SELECT ALL USERS IN TABLE //
  /***************************************************************/
  selectAllUser(i) {
    if (i.checkbox === true) {
      this.tableData.data.forEach((element: any) => {
        element.checkbox = true;
        element.isSelected = true;
        if(this.type!=='csvLeadsList1'){
          if (this.selectedUserList.length) {
            const x = this.selectedUserList.findIndex((o) => o._id === element._id);
            if (x === -1) {
              this.selectedUserList.push(element);
            }
          } else {
            this.selectedUserList.push(element);
          }
        } else if(this.type==='csvLeadsList1'){
          const x = this.selectedFileList.findIndex((o) => o === element._id);
          if (x === -1) {
            this.selectedFileList.push(element._id);
          }
        } else {
          this.selectedFileList.push(element._id);
        
        }
       
      });
    } else {
      this.tableData.data.forEach((element: any) => {
        element.checkbox = false;
        element.isSelected = false;
        if(this.type=='csvLeadsList'){
        const x = this.selectedUserList.findIndex((o: any) => o._id === element._id);
        if (x > -1) {
          this.selectedUserList.splice(x, 1);
        }
        } else{
          const x = this.selectedFileList.findIndex((o: any) => o === element._id);
          if (x > -1) {
            this.selectedFileList.splice(x, 1);
          }
        }
      });
    }
  }
  UnselectUsers() {
    this.tableConfig.cols.map((element: any) => {
      if (element.type === 'multipleSelection') {
        element.checkbox = false;
      }
    });
    // tslint:disable-next-line: no-identical-functions
    this.tableData.data.forEach((element: any) => {
      element.checkbox = false;
      element.isSelected = false;
      const x = this.selectedUserList.findIndex((o: any) => o._id === element._id);
      if (x > -1) {
        this.selectedUserList.splice(x, 1);
      }
    });
  }
  selectMultipleUser(data) {
    if (data.checkbox) {
      
      if(this.type !=='csvLeadsList1'){
        if (this.selectedUserList.length) {
          const x = this.selectedUserList.findIndex((o: any) => o._id === data._id);
          if (x === -1) {
            this.selectedUserList.push(data);
          }
        } else {
          this.selectedUserList.push(data);
        }
      } else if(this.type ==='csvLeadsList1'){
        if (this.selectedFileList.length) {
          const x = this.selectedFileList.findIndex((o: any) => o === data._id);
          if (x === -1) {
            this.selectedFileList.push(data._id);
          }
        } else {
          this.selectedFileList.push(data._id);
        }
      }
    } else {
      this.tableConfig.cols.forEach((element: any) => {
        element.checkbox = false;
      });
      if(this.type !=='csvLeadsList1'){
      const x = this.selectedUserList.findIndex((o: any) => o._id === data._id);
      if (x > -1) {
        const index = this.tableData.data.findIndex((i: any) => i._id === this.selectedUserList[x]._id);
        this.tableData.data[index].isSelected = false;
        this.selectedUserList.splice(x, 1);
      }
    } else   if(this.type ==='csvLeadsList1'){
      const x = this.selectedFileList.findIndex((o: any) => o === data._id);
      if (x > -1) {
        const index = this.tableData.data.findIndex((i: any) => i._id === this.selectedFileList[x]);
        this.tableData.data[index].isSelected = false;
        this.selectedFileList.splice(x, 1);
      }
    }
  }
  if (this.type === 'Sendnotificationlist') {
    data.isSelected = true;
  }
  }
  // selectSingleUser(data, event) {
  //   if (event.target.checked) {
  //     this.seletedCSVTemplate = [];
  //     this.seletedCSVTemplate.push(data);
  //     this.seletedUserIds = JSON.parse(this.getToken('selectedUserList'));
  //   } else {
  //     if (this.seletedCSVTemplate.length) {
  //       this.seletedCSVTemplate.splice(0, 1);
  //       console.log(this.seletedCSVTemplate);

  //     }
  //   }
  // }
  sendSeletedLeadNotification() {
    let finalData = {
      ids: this.selectedFileList,
      templateId: this.type!=='csvLeadsList1'?this.seletedCSVTemplate[0]._id:this.params.statusParams,
      role: 'admin'
    }
    this.commonService.callApi('csvleadfile/sendMultipleMail', finalData, 'post', false, false).then((success: any) => {
      if (success.status === 1) {
        if(this.type =='csvLeadsList'){
          this.router.navigate([this.URLConstants.CsvLeads]);
        } else{
          this.BsModalRef.hide();
        }
        this.removeToken('selectedUserList');
        this.popToast('success', success.message);
      } else{
        this.popToast('error', success.message);
      }
    });
  }
}

